import * as Yup from "yup";

export interface LoginFormSchema {
  username: string;
  password: string;
}

export const initialValues: LoginFormSchema = {
  username: "",
  password: "",
};

export const validations = Yup.object().shape({
  username: Yup.string().required().min(2),
  password: Yup.string().required().min(2),
});
