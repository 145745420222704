import { Badge, IconButton } from "@mui/material";
import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks";

const CartButton = () => {
  const navigate = useNavigate();
  const handleNavigateToCart = () => navigate("/shoppingBag");
  const { order } = useAppSelector((state) => state.shoppingBag);

  return (
    <IconButton onClick={handleNavigateToCart} color="inherit">
      <Badge badgeContent={order?.saleorder_detail.length} color="error">
        <ShoppingBagTwoToneIcon />
      </Badge>
    </IconButton>
  );
};

export default CartButton;
