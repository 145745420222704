import { Backdrop, Portal, Typography } from "@mui/material";
import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import styled, { keyframes } from "styled-components";
import { bounce } from "react-animations";

const bounceAnimation = keyframes`${bounce}`;

const BouncyDiv = styled.div`
  animation: 1s ${bounceAnimation} infinite;
`;

const LoadingShoppingBag: React.FC = () => {
  return (
    <Portal>
      <Backdrop
        component="div"
        open
        sx={{
          color: "white",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <BouncyDiv>
          <ShoppingBagTwoToneIcon sx={{ fontSize: 100 }} />
        </BouncyDiv>
        <Typography variant="h4">Cargando carrito</Typography>
        <Typography variant="caption">Por favor espera un momento</Typography>
      </Backdrop>
    </Portal>
  );
};

export default LoadingShoppingBag;
