import { Box, Card, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Product from "../../models/product";
import capitalizeFirstLetter from "../../utils/capitalize";
import Image from "../images/image";

type Props = {
  product: Product;
};

const ProductBigCard: React.FC<Props> = ({ product }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/product/${product.productid}`);
  };

  return (
    <Card sx={{ height: "100%", width: "100%" }} onClick={handleOnClick}>
      <Grid container>
        <Grid item xs={12} md={6} xl={5}>
          <Image
            alt={product.description}
            srcImage={product.inv_productimages?.at(0)?.url}
            height="100%"
            width="100%"
          />
        </Grid>
        <Grid item xs={12} md={6} xl={7}>
          <Box sx={{ padding: "18px 20px" }}>
            <Typography variant="h4">
              {capitalizeFirstLetter(product.name)}
            </Typography>
            <Typography variant="caption">{product.sku}</Typography>
            <Typography variant="body1">
              {capitalizeFirstLetter(product.description)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductBigCard;
