import { Box, Typography } from "@mui/material";
import SentimentDissatisfiedTwoToneIcon from "@mui/icons-material/SentimentDissatisfiedTwoTone";

type Props = {};

const NoProductGrid: React.FC<Props> = () => {
  return (
    <Box
      height="120px"
      width="100%"
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <SentimentDissatisfiedTwoToneIcon sx={{ fontSize: 100 }} />
      <Typography variant="h6">No se encontraron coincidencias</Typography>
    </Box>
  );
};

export default NoProductGrid;
