import Order, { BaseOrder } from "../../models/order";
import OrderForm from "../order/form/orderForm";
import { useAppDispatch } from "../../hooks";
import { updateOrder } from "../../redux/shoppingBag/shoppingBagReducer";

type Props = {
  order: Order;
};

const ShoppingBagHeader: React.FC<Props> = ({ order }) => {
  const dispatch = useAppDispatch();

  const onSubmit = (values: BaseOrder) => {
    dispatch(updateOrder({ ...order, ...values }));
  };

  return (
    <OrderForm
      onSubmit={onSubmit}
      submitButtonText="Guardar"
      initialValues={{ ...order }}
    />
  );
};

export default ShoppingBagHeader;
