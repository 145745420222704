import { Box, CircularProgress, Typography } from "@mui/material";

const LoadingOrder: React.FC = () => {
  return (
    <Box>
      <CircularProgress />
      <Typography variant="h6">Buscando pedidos</Typography>
    </Box>
  );
};

export default LoadingOrder;