import { Box, Typography } from "@mui/material";
import ImageNotSupportedTwoToneIcon from "@mui/icons-material/ImageNotSupportedTwoTone";

type Props = {
  height?: number | string;
  width?: number | string;
};

const ImageNotFound: React.FC<Props> = ({
  width = "100%",
  height = "100%",
}) => {
  return (
    <Box
      sx={{
        width,
        height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ImageNotSupportedTwoToneIcon fontSize="large" />
      <Typography gutterBottom variant="h6" component="div">
        Imagen no encontrada
      </Typography>
    </Box>
  );
};

export default ImageNotFound;
