import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LoginForm from "../components/user/login/form/form";
import Logo from "../components/logo/logo";
import Copyright from "../components/copyright/copyright";

const Login: React.FC = () => {
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        paddingY: 10,
      }}
    >
      <Logo />
      <Box sx={{ mt: 2, width: "100%", textAlign: "center" }}>
        <Typography component="h1" variant="h5" mb={2}>
          Inicio de Sesión
        </Typography>
        <LoginForm />
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default Login;
