import {
  Toolbar,
  AppBar as MaterialAppBar,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Logo } from "../../logo";
import buttons from "../navigationButtons";
import NavigationButton from "../navigationButtons/navigationButton";
import CartButton from "./cartButton";
import Avatar from "./avatar";

const AppBar: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <MaterialAppBar position="fixed" color="secondary">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Link
            to="/home/products"
            style={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Logo />
          </Link>

          <Stack
            gap={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: "490px",
              justifyContent: matches ? "space-between" : "flex-end",
            }}
          >
            {matches &&
              buttons
                .filter((e) => !e.hideInAppbar)
                .map((e) => (
                  <NavigationButton
                    key={e.route}
                    buttonProps={e}
                    type="appbar"
                  />
                ))}
            <CartButton />
            <Avatar />
          </Stack>
        </Toolbar>
      </MaterialAppBar>
    </>
  );
};

export default AppBar;
