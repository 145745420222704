import { Container } from "@mui/material";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AppBar } from "../components/layout";
import OrderDetailList from "../components/order/orderDetailList";
import OrderDetails from "../components/order/orderDetails";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {
  cancelOrder,
  deleteOrder,
  loadExistingOrder,
} from "../redux/shoppingBag/shoppingBagReducer";
import { clear, emitOrder, loadOrder } from "../redux/order/orderReducer";
import { useSnackbar } from "notistack";
import OrderSkeleton from "../components/order/orderSkeleton";

const Order: React.FC = () => {
  const dispatch = useAppDispatch();
  const { status, order } = useAppSelector((state) => state.order);
  const navigate = useNavigate();

  const location = useLocation();
  const { orderid } = useParams();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const getData = async () => {
    if (orderid && status === "idle") {
      await dispatch(loadOrder(parseInt(orderid)));
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    getData();
    return () => {
      dispatch(clear());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleEmit = async () => {
    if (order) {
      enqueueSnackbar("Emitiendo pedido...", { variant: "info" });
      const data = await dispatch(emitOrder(order.saleorderid));
      closeSnackbar();
      enqueueSnackbar(`Pedido #${data.payload.data.documentno} emitido correctamente`, {
        variant: "success",
      });
      await dispatch(loadOrder(order.saleorderid));
    }
  };

  const handleEditOrder = async () => {
    if (order) {
      dispatch(loadExistingOrder(order));
      enqueueSnackbar("Orden cargada y lista para modificar", {
        variant: "success",
      });
      navigate("/shoppingBag");
    }
  };

  const handleCancelOrder = async (orderid: number, reason: string) => {
    if (order) {
      try {
        await dispatch(cancelOrder({ orderid, reason }));
        enqueueSnackbar("Pedido anulado", {
          variant: "info",
        });
        await dispatch(loadOrder(orderid));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDeleteOrder = async (orderid: number) => {
    if (order) {
      try {
        await dispatch(deleteOrder(orderid));
        enqueueSnackbar("Orden Eliminada", {
          variant: "info",
        });
        navigate(-1);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      <AppBar />
      <Container sx={{ paddingBottom: 8, paddingTop: 10, height: "100vh" }}>
        {status === "succeeded" && order ? (
          <>
            <OrderDetails
              order={order}
              onEdit={handleEditOrder}
              onCancel={handleCancelOrder}
              onDelete={handleDeleteOrder}
              onEmit={handleEmit}
            />
            <OrderDetailList
              detail={order.saleorder_detail ?? []}
              enableEdit={order.status === "D"}
            />
          </>
        ) : status === "failed" ? (
          <>error</>
        ) : (
          <>
            <OrderSkeleton />
            <OrderDetailList detail={[]} enableEdit={false} isLoading />
          </>
        )}
      </Container>
    </>
  );
};

export default Order;
