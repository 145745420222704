import { BottomNavigationAction, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import NavigationButtonProps from "./props";

const BottomNavigationActionButton: React.FC<NavigationButtonProps> = ({
  icon,
  label,
  route,
  onClick,
}) => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <BottomNavigationAction
      label={label}
      value={route}
      icon={icon}
      sx={{
        color: location.pathname === route ? theme.palette.primary.main : theme.palette.text.secondary,
      }}
      showLabel={location.pathname === route}
      onClick={onClick}
    />
  );
};

export default BottomNavigationActionButton;
