import { Box, Divider, Skeleton, Typography } from "@mui/material";

const OrderSkeleton = () => {
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton width={140} />
        <Skeleton width={80} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">
          <Skeleton width={200} />
        </Typography>
        <Skeleton width={40} height={40} variant="circular" />
      </Box>
      <Typography variant="caption">
        <Skeleton width="100%" sx={{ maxWidth: 100 }} />
        <Skeleton width={300} />
      </Typography>
      <Typography variant="body2">
        <Skeleton width={100} />
      </Typography>
      <Divider sx={{ marginY: 2 }} />
    </Box>
  );
};

export default OrderSkeleton;
