import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import {
  AuthenticationStatus,
  requestLogin,
} from "../../../../redux/user/authenticationReducer";
import { initialValues, LoginFormSchema, validations } from "./form_schema";

const LoginForm: React.FC = () => {
  const { authenticationStatus, error } = useAppSelector(
    (state) => state.authentication
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (authenticationStatus === AuthenticationStatus.authenticated) {
      navigate("/home");
    }
  }, [authenticationStatus, navigate]);

  const formik = useFormik<LoginFormSchema>({
    initialValues,
    validationSchema: validations,
    onSubmit: async (values) => {
      await dispatch(requestLogin(values));
    },
  });

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <TextField
        fullWidth
        id="username"
        name="username"
        label="Usuario"
        value={formik.values.username}
        onChange={formik.handleChange}
        error={formik.touched.username && Boolean(formik.errors.username)}
        helperText={formik.touched.username && formik.errors.username}
        autoFocus
      />
      <TextField
        fullWidth
        id="password"
        name="password"
        label="Password"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      {error && <Typography color="red">{error}</Typography>}
      <Box>
        <Button color="primary" variant="contained" type="submit">
          Iniciar Sesión
        </Button>
      </Box>
    </Box>
  );
};

export default LoginForm;
