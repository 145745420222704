import { Box, Divider, Typography } from "@mui/material";
import OrderDetailList from "../order/orderDetailList";
import OrderItem from "../../models/orderItem";
import currency from "../../utils/currency";

type Props = {
  items: OrderItem[];
  total: number;
};

const ShoppingBagDetails: React.FC<Props> = ({ items, total }) => {
  return (
    <>
      <Box mt={2}>
        <OrderDetailList
          detail={items}
          enableEdit
          isShoppingBag
          title="Productos agregados a la cesta"
        />
      </Box>
      <Divider sx={{ marginY: 2 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6">Total este pedido:</Typography>
        <Typography variant="h5">{currency.format(total)}</Typography>
      </Box>
    </>
  );
};

export default ShoppingBagDetails;
