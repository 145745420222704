import axiosInstance from "../axiosInterceptors";

export type orderBy = "name" | "sku" | "publicprice";
export type order = "asc" | "desc";

export interface ProductAPIParams {
  offset?: number;
  maxItems?: number;
  orderby?: orderBy;
  order?: order;
  text?: string;
  baseproduct?: boolean;
  starred?: boolean;
  categoryid?: number;
}

class ProductAPI {
  private static path: string = "api/inv/products";

  public getProducts = async (productAPIOptions: ProductAPIParams) => {
    var response = await axiosInstance.get(`${ProductAPI.path}/store`, {
      params: productAPIOptions,
    });
    return response;
  };

  public getProductByID = async (productid: number) => {
    var response = await axiosInstance.get(
      `${ProductAPI.path}/store/${productid}`
    );
    return response;
  };

  public getProductImages = async (productid: number) => {
    var response = await axiosInstance.get(
      `${ProductAPI.path}/images/${productid}`
    );
    return response;
  };

  public getProductChildren = async (productid: number) => {
    var response = await axiosInstance.get(
      `${ProductAPI.path}/children/${productid}`
    );
    return response;
  };
}

export default ProductAPI;
