import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchState from "../fetchStates";
import Product from "../../models/product";
import ProductAPI, { ProductAPIParams } from "../../repository/product";

interface ProductListState {
  products: Product[];
  limit: number;
  page: number;
  filters: ProductAPIParams;
  disableSearchText: boolean;
  status: fetchState;
  error: string | null;
}

const baseFilters: ProductAPIParams = {
  order: "asc",
  orderby: "name",
  text: "",
  categoryid: undefined,
  baseproduct: true,
  maxItems: 20,
  offset: 0,
};

const initialState: ProductListState = {
  products: [],
  filters: baseFilters,
  page: 0,
  limit: 20,
  disableSearchText: false,
  error: null,
  status: "idle",
};

export const productListSlice = createSlice({
  name: "productsList",
  initialState,
  reducers: {
    clear: (state) => {
      state.products = [];
      state.filters.offset = 0;
      state.filters.maxItems = 20;
      state.filters.baseproduct = true;
      state.page = 0;
      state.limit = 20;
      state.status = "idle";
    },
    setDisableSearchText: (state, action) => {
      state.disableSearchText = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...action.payload, maxItems: 20, baseproduct: true };
    },
    changeSearchText: (state, action) => {
      state.filters.text = action.payload;
    },
    toggleOrder: (state) => {
      state.filters.order = state.filters.order === "asc" ? "desc" : "asc";
    },
    changeOrderBy: (state, action) => {
      state.filters.orderby = action.payload;
    },
    changeCategory: (state, action) => {
      state.filters.categoryid = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(requestProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(requestProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = [...state.products, ...action.payload.data.items];
        state.limit = action.payload.data.totalItems;
        state.filters.offset =
          (state.page + 1) * (state.filters.maxItems ?? 20);
        state.page += 1;
      })
      .addCase(requestProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "something wrong";
      });
  },
});

export const requestProducts = createAsyncThunk(
  "productsList/getProducts",
  async (_, thunkAPI) => {
    const currentState = (thunkAPI.getState() as any)
      .productList as ProductListState;
    const response = await new ProductAPI().getProducts({
      ...currentState.filters,
      text: currentState.disableSearchText
        ? undefined
        : currentState.filters.text,
    });
    return response.data;
  }
);

export const {
  clear,
  changeCategory,
  toggleOrder,
  changeOrderBy,
  changeSearchText,
  setFilters,
  setDisableSearchText,
} = productListSlice.actions;
export default productListSlice.reducer;
