import { useMediaQuery } from "@mui/material";
import darkLogo from'../../assets/logo-banner-dark.png';
import lightLogo from'../../assets/logo-banner-light.png';

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <img
      src={!prefersDarkMode ? darkLogo : lightLogo}
      alt="logo_image"
      loading="lazy"
    />
  );
}

export default App;
