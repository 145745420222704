import {
  Modal,
  Card,
  Portal,
  Typography,
  CardContent,
  CardActions,
  Button,
  TextField,
} from "@mui/material";
import { useState } from "react";

type Props = {
  orderid: number;
  open: boolean;
  onConfirm: (cancelReason: string) => void;
  onCancel: () => void;
};

const CancelOrderModal: React.FC<Props> = ({orderid, open, onConfirm, onCancel }) => {
  const [cancelReason, setCancelReason] = useState<string>("");

  const handleChangeDescription = (e: any) => {
    setCancelReason(e.target.value);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = () => {
    onConfirm(cancelReason);
  };

  return (
    <Portal>
      <Modal
        keepMounted
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card
          sx={{
            position: "absolute",
            juistifyContent: "center",
            alighItems: "center",
            maxWidth: 500,
            width: "100%",
            borderWidth: 0,
            padding: 2,
          }}
        >
          <CardContent>
            <Typography id="modal-title" variant="h6" component="h2">
              Anular pedido
            </Typography>
            <Typography id="modal-description" sx={{ my: 1 }}>
              Esta seguro de anular el pedido #{orderid}?
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={3}
              label="Descripción"
              variant="outlined"
              value={cancelReason}
              onChange={handleChangeDescription}
            />
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button onClick={handleCancel} variant="text">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="error">
              Sí, Anular pedido
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </Portal>
  );
};

export default CancelOrderModal;
