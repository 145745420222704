import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Product from "../../models/product";
import capitalizeFirstLetter from "../../utils/capitalize";
import Image from "../images/image";

type Props = {
  product: Product;
};

const ProductCard: React.FC<Props> = ({ product }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate(`/product/${product.productid}`);
  };

  return (
    <Card sx={{ height: 470 }} onClick={handleOnClick} variant="outlined">
      <Image
        height={225}
        srcImage={product.inv_productimages?.at(0)?.url}
        alt={product.description}
      />

      <CardContent sx={{ height: "40%" }}>
        <Typography variant="caption">{product.sku}</Typography>
        <Typography
          gutterBottom
          variant="h5"
          sx={{
            width: "100%",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: 0,
          }}
        >
          {capitalizeFirstLetter(product.name)}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            width: "100%",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {capitalizeFirstLetter(product.description)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Ver más</Button>
      </CardActions>
    </Card>
  );
};

export default ProductCard;
