import { configureStore } from "@reduxjs/toolkit";
import orderReducer from "./order/orderReducer";
import shoppingBagReducer from "./shoppingBag/shoppingBagReducer";
import ordersReducer from "./order/ordersReducer";
import productListReducer from "./product/productListReducer";
import productReducer from "./product/productReducer";
import starredProductListReducer from "./product/starredProductListReducer";
import authenticationReducer from "./user/authenticationReducer";

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    product: productReducer,
    productList: productListReducer,
    starredProductList: starredProductListReducer,
    order: orderReducer,
    orders: ordersReducer,
    shoppingBag: shoppingBagReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;