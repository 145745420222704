import { Box, Button, Grid, Typography } from "@mui/material";
import moment from "moment";
import Order from "../../models/order";
import ModeEditOutlineTwoToneIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import RemoveCircleTwoToneIcon from "@mui/icons-material/RemoveCircleTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CancelOrderModal from "./cancelOrderModal";
import { useState } from "react";
import OrderStatus from "./orderStatus";
import currency from "../../utils/currency";
import DeleteOrderModal from "./deleteOrderModal";

type Props = {
  order: Order;
  onEmit?: (orderID: number) => void;
  onEdit?: (orderID: number) => void;
  onCancel?: (orderID: number, reason: string) => void;
  onDelete?: (orderID: number) => void;
};

const OrderDetails: React.FC<Props> = ({
  order,
  onEmit,
  onEdit,
  onCancel,
  onDelete,
}) => {
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const canEmit = order.status === "D";
  const canEdit = onEdit && order.status === "D";
  const canDelete = onDelete && order.status === "D";
  const canCancel = onCancel && order.status === "I";

  const handleClickCancelButton = () => {
    setCancelModalOpen(true);
  };

  const handleCancelCancelOrder = () => {
    setCancelModalOpen(false);
  };

  const handleClickDeleteButton = () => {
    setDeleteModalOpen(true);
  };

  const handleCancelDeleteOrder = () => {
    setDeleteModalOpen(false);
  };

  const handleConfirmCancelOrder = (cancelReason: string) => {
    onCancel && onCancel(order.saleorderid, cancelReason);
    handleCancelCancelOrder();
  };

  const handleConfirmDeleteOrder = () => {
    onDelete && onDelete(order.saleorderid);
    handleCancelDeleteOrder();
  };
  const handleEditOrder = () => {
    onEdit && onEdit(order.saleorderid);
  };

  const handleEmit = () => {
    onEmit && onEmit(order.saleorderid);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Typography variant="h6">Pedido #{order.documentno}</Typography>
        <OrderStatus status={order.status} />
        <Typography variant="h5">Nombre: {order.name}</Typography>
        <Typography variant="body2">
          DPI: {order.documentid ?? "-"}
        </Typography>
        <Typography variant="body2">
          Teléfono: {order.phonenumber ?? "-"}
        </Typography>
        <Typography variant="body2">
          Dirección: {order.address ?? "-"}
        </Typography>
        <Typography variant="body2">Comentario: {order.comment}</Typography>
        <Typography color="text.secondary" variant="caption">
          Creado: {moment(order.createdAt).format("DD/MM/yyyy HH:mm")}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          Actualizado:{" "}
          {order.updatedAt ? moment(order.updatedAt).format("DD/MM/yyyy HH:mm") : "-"}
        </Typography>
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          Total este pedido: <br />
          {currency.format(order.total)}
        </Typography>
      </Box>
      <Grid container spacing={1}>
        {canEmit && (
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              type="button"
              variant="contained"
              size="large"
              startIcon={<CheckCircleTwoToneIcon />}
              onClick={handleEmit}
              color="success"
              disabled={!(order.total > 0)}
            >
              Emitir
            </Button>
          </Grid>
        )}
        {canEdit && (
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              onClick={handleEditOrder}
              startIcon={<ModeEditOutlineTwoToneIcon />}
            >
              Editar este pedido
            </Button>
          </Grid>
        )}
        {canDelete && (
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              onClick={handleClickDeleteButton}
              startIcon={<DeleteTwoToneIcon />}
              color="error"
            >
              Eliminar este pedido
            </Button>
          </Grid>
        )}
        {canCancel && (
          <Grid item xs={12} sm={4}>
            <Button
              fullWidth
              onClick={handleClickCancelButton}
              color="error"
              startIcon={<RemoveCircleTwoToneIcon />}
            >
              Anular Pedido
            </Button>
          </Grid>
        )}
      </Grid>
      <DeleteOrderModal
        order={order}
        onCancel={handleCancelDeleteOrder}
        onConfirm={handleConfirmDeleteOrder}
        open={deleteModalOpen}
      />
      <CancelOrderModal
        orderid={order.documentno}
        onCancel={handleCancelCancelOrder}
        onConfirm={handleConfirmCancelOrder}
        open={cancelModalOpen}
      />
    </>
  );
};

export default OrderDetails;
