import { Container } from "@mui/material";
import { AppBar, BottomNavigation } from "../components/layout";
import { useAppSelector } from "../hooks";
import ShoppingBagData from "../components/shoppingBag/shoppingBagData";
import NoSelectedOrder from "../components/shoppingBag/noSelectedOrder";
import LoadingShoppingBag from "../components/shoppingBag/loadingShoppingBag";
import { useEffect } from "react";

const ShoppingBag: React.FC = () => {
  const { order, status } = useAppSelector((state) => state.shoppingBag);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <AppBar />
      <Container sx={{ height: status === "loading" ? "100vh" : undefined }}>
        {status === "loading" ? (
          <LoadingShoppingBag />
        ) : order ? (
          <ShoppingBagData order={order} />
        ) : (
          <NoSelectedOrder />
        )}
      </Container>
      <BottomNavigation />
    </>
  );
};

export default ShoppingBag;
