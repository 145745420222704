import { Button } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import NavigationButtonProps from "./props";

const AppBarNavigationButtons: React.FC<NavigationButtonProps> = ({
  icon,
  label,
  route,
  onClick,
}) => {
  const location = useLocation();
  const active = location.pathname === route;

  return (
    <Button
      startIcon={icon}
      LinkComponent={Link}
      onClick={onClick}
      disableElevation
      color={active ? "primary" : "inherit"}
      variant={active ? "contained" : "outlined"}
      sx={{ borderWidth: 0 }}
    >
      {label}
    </Button>
  );
};

export default AppBarNavigationButtons;
