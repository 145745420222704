import {
  Box,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";

const OrderCardSkeleton = () => {
  return (
    <Box mb={2}>
      <Card variant="outlined">
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              <Skeleton width={100} />
            </Typography>
            <Typography variant="h6" sx={{ textAlign: "end" }}>
              <Skeleton width={100} />
            </Typography>
          </Box>
          <Typography variant="h5">
            <Skeleton />
          </Typography>
          <Typography sx={{ mb: 1.5 }} color="text.secondary">
            <Skeleton />
          </Typography>
          <Typography variant="body2">
            <Skeleton />
          </Typography>
        </CardContent>
        <CardActions>
          <Skeleton variant="rounded" width={50} />
        </CardActions>
      </Card>
    </Box>
  );
};

export default OrderCardSkeleton;
