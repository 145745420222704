import { Box, TextField, Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import OrderItem from "../../../models/orderItem";
import { initialValues as formInitialValues, validations } from "./validations";
import { BaseOrder } from "../../../models/order";
import { useEffect } from "react";

type Props = {
  orderItem?: OrderItem;
  submitButtonText?: String;
  initialValues?: BaseOrder;
  onSubmit: (value: BaseOrder) => void;
};

const OrderForm: React.FC<Props> = ({
  orderItem,
  submitButtonText = "Registrar",
  initialValues,
  onSubmit,
}) => {
  const formik = useFormik({
    validationSchema: validations,
    validateOnChange: true,
    initialValues: {
      ...formInitialValues,
      ...initialValues,
      saleorder_detail: orderItem ? [orderItem] : [],
    },
    onSubmit: onSubmit,
  });

  useEffect(() => {
    orderItem && formik.setFieldValue("saleorder_detail", [orderItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItem]);

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={7}>
          <TextField
            id="name"
            name="name"
            label="Pedido a nombre de"
            fullWidth
            required
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            id="phonenumber"
            name="phonenumber"
            label="Teléfono de cliente"
            fullWidth
            required
            value={formik.values.phonenumber}
            onChange={formik.handleChange}
            error={
              formik.touched.phonenumber && Boolean(formik.errors.phonenumber)
            }
            helperText={formik.touched.phonenumber && formik.errors.phonenumber}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="taxid"
            name="taxid"
            label="NIT de cliente"
            fullWidth
            value={formik.values.taxid}
            onChange={formik.handleChange}
            error={formik.touched.taxid && Boolean(formik.errors.taxid)}
            helperText={formik.touched.taxid && formik.errors.taxid}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="businessname"
            name="businessname"
            label="Razón social"
            fullWidth
            value={formik.values.businessname}
            onChange={formik.handleChange}
            error={
              formik.touched.businessname && Boolean(formik.errors.businessname)
            }
            helperText={
              formik.touched.businessname && formik.errors.businessname
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="documentid"
            name="documentid"
            label="DPI de cliente"
            fullWidth
            value={formik.values.documentid}
            onChange={formik.handleChange}
            error={
              formik.touched.documentid && Boolean(formik.errors.documentid)
            }
            helperText={formik.touched.documentid && formik.errors.documentid}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="adress"
            name="address"
            label="Dirección de cliente"
            fullWidth
            required
            value={formik.values.address}
            onChange={formik.handleChange}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="comment"
            name="comment"
            label="Comentarios"
            fullWidth
            multiline
            minRows={2}
            value={formik.values.comment}
            onChange={formik.handleChange}
            error={formik.touched.comment && Boolean(formik.errors.comment)}
            helperText={formik.touched.comment && formik.errors.comment}
          />
        </Grid>
      </Grid>

      <Box sx={{ marginLeft: "auto", marginTop: 1, maxWidth: 150 }}>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => formik.submitForm()}
          disabled={formik.initialValues === formik.values}
        >
          {submitButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default OrderForm;
