import NavigationButtonProps from "./props";
import Inventory2TwoToneIcon from "@mui/icons-material/Inventory2TwoTone";
import ReceiptLongTwoToneIcon from "@mui/icons-material/ReceiptLongTwoTone";

const buttons: NavigationButtonProps[] = [
  {
    label: "Productos",
    route: "/home/products",
    icon: <Inventory2TwoToneIcon />,
  },
  {
    label: "Pedidos",
    route: "/home/orders",
    icon: <ReceiptLongTwoToneIcon />,
  },
];

export default buttons;
