import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { dark, light } from "../../themes";

type Props = {
  children: JSX.Element[] | JSX.Element;
};

const Page = ({ children }: Props) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  return (
    <ThemeProvider theme={prefersDarkMode ? dark : light}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default Page;
