import { Typography, useTheme } from "@mui/material";

type Props = {
  balance: number;
  hideBalance?: boolean;
};

const ProductAvailability: React.FC<Props> = ({ balance, hideBalance }) => {
  const theme = useTheme();
  return (
    <Typography
      variant="subtitle1"
      color={
        balance > 0 ? theme.palette.secondary.main : theme.palette.error.main
      }
    >
      {hideBalance && balance > 0
        ? "Disponible"
        : balance > 0
        ? `Disponible: ${parseInt(balance.toString())}`
        : "Sin existencias"}
    </Typography>
  );
};

export default ProductAvailability;
