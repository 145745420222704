import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchState from "../fetchStates";
import Product from "../../models/product";
import ProductAPI, { ProductAPIParams } from "../../repository/product";

interface ProductListState {
  products: Product[];
  limit: number;
  status: fetchState;
  error: string | null;
}

const initialState: ProductListState = {
  products: [],
  limit: 20,
  error: null,
  status: "idle",
};

export const starredProductList = createSlice({
  name: "starredProductList",
  initialState,
  reducers: {
    clear: (state) => {
      state.products = [];
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(requestStarredProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(requestStarredProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = [...state.products, ...action.payload.data.items];
        state.limit = action.payload.data.totalItems;
      })
      .addCase(requestStarredProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "something wrong";
      });
  },
});

export const requestStarredProducts = createAsyncThunk(
  "starredProductList/getStarredProducts",
  async (params?: ProductAPIParams) => {
    const response = await new ProductAPI().getProducts({...params, starred: true});
    return response.data;
  }
);

export const { clear } = starredProductList.actions;
export default starredProductList.reducer;
