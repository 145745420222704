import axiosInstance from "../axiosInterceptors";
import Order, { BaseOrder, OrderStatus } from "../models/order";

export interface OrderAPIParams {
  offset?: number;
  maxItems?: number;
  orderby?: string;
  order?: "ASC" | "DESC";
  status?: OrderStatus;
}

export interface CancelOrder {
  orderid: number;
  reason: string;
}

class OrderAPI {
  private static path: string = "api/inv/salesorders";

  public getOrders = async (orderAPIParams: OrderAPIParams) => {
    var response = await axiosInstance.get(OrderAPI.path, {
      params: orderAPIParams,
    });
    return response;
  };

  public getOrderByID = async (orderid: number) => {
    var response = await axiosInstance.get(`${OrderAPI.path}/${orderid}`);
    return response;
  };

  public getOrderDetails = async (orderid: number) => {
    var response = await axiosInstance.get(
      `${OrderAPI.path}/details/${orderid}`
    );
    return response;
  };

  public createOrder = async (order: BaseOrder) => {
    var response = await axiosInstance.post(OrderAPI.path, {
      data: order,
    });
    return response;
  };

  public updateOrder = async (order: Order) => {
    var response = await axiosInstance.put(
      `${OrderAPI.path}/${order.saleorderid}`,
      {
        data: order,
      }
    );
    return response;
  };

  public emitOrder = async (orderid: number) => {
    var response = await axiosInstance.put(`${OrderAPI.path}/${orderid}/emit`);
    return response;
  };

  public cancelOrder = async (cancel: CancelOrder) => {
    var response = await axiosInstance.put(
      `${OrderAPI.path}/${cancel.orderid}/cancel`,
      {
        data: {
          cancelComment: cancel.reason,
        },
      }
    );
    return response;
  };
  public deleteOrder = async (orderid: number) => {
    var response = await axiosInstance.delete(`${OrderAPI.path}/${orderid}`);
    return response;
  };
}

export default OrderAPI;
