import { Link, Typography } from "@mui/material";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"2022 © Powered by"}
      <Link color="inherit" href="http://besidescode.com">
        BesidesCode
      </Link>
      {"."}
    </Typography>
  );
}

export default Copyright;
