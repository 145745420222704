import { Box, ListItem, Typography } from "@mui/material";
import capitalizeFirstLetter from "../../../utils/capitalize";
import Image from "../../images/image";
import Props from "./props";
import DeleteItemButton from "./deleteButton";
import currency from "../../../utils/currency";

const MDOrderDetailItem: React.FC<Props> = ({
  orderItem,
  isLg = false,
  isEditing = false,
  onProductClick,
  onRemoveItem,
}) => {
  const { inv_product, price, quantity, subtotal } = orderItem;

  const handleDelete = () => {
    onRemoveItem && onRemoveItem();
  };

  const cursor = onProductClick ? "pointer" : "auto";
  return (
    <ListItem>
      {isEditing && <DeleteItemButton onDelete={handleDelete} />}
      <Box
        sx={{ marginRight: 2, cursor }}
        component="div"
        onClick={onProductClick}
      >
        <Image
          alt="sfasd"
          srcImage={inv_product?.inv_productimages?.at(0)?.url ?? ""}
          height={isLg ? 250 : 200}
          width={isLg ? 250 : 200}
        />
      </Box>

      <Box sx={{ width: "100%" }}>
        <Typography
          variant="caption"
          component="div"
          onClick={onProductClick}
          sx={{ cursor }}
        >
          {inv_product.sku}
        </Typography>
        <Typography
          component="div"
          onClick={onProductClick}
          gutterBottom
          variant="h5"
          sx={{
            width: "100%",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: 0,
            cursor,
          }}
        >
          {capitalizeFirstLetter(inv_product.name)}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography>Precio: {currency.format(price)}</Typography>
            <Typography>Cantidad: {quantity}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <Typography variant="h6">Sub Total</Typography>
            <Typography variant="h6">{currency.format(subtotal)}</Typography>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

export default MDOrderDetailItem;
