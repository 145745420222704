import { createTheme } from "@mui/material";

const themeOptions = createTheme({
  palette: {
    mode: "light",
    primary: {
      // main: "#a78db9",
      main: "#816096",
    },
    secondary: {
      main: "#b592ca",
    },
  },
});

export default themeOptions;
