import { OrderStatus } from "../models/order";

export const orderStateToString = (orderState: OrderStatus) => {
  switch (orderState) {
    case "C":
      return "Anulado";
    case "D":
      return "Borrador";
    case "I":
      return "Emitido";
    case "P":
      return "Despachado";
  }
};
