import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Size from "../../models/size";

type Props = {
  selectedID?: number;
  sizes: Size[];
  onChange: (id: number) => void;
};

const ProductSizes: React.FC<Props> = ({ sizes, selectedID, onChange }) => {
  if (sizes.length === 0) {
    return null;
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-disabled-label">Tallas</InputLabel>
      <Select
        labelId="size-select"
        id="size-select"
        value={selectedID ?? ''}
        fullWidth
        label="Tallas"
        onChange={(e) => onChange(e.target.value as number)}
      >
        {sizes.map((size) => (
          <MenuItem value={size.sizeid} key={size.sizeid + size.code}>
            {size.code} - {size.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ProductSizes;
