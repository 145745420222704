import { List, ListItemButton, ListItemText, Typography } from "@mui/material";
import Order from "../../models/order";
import currency from "../../utils/currency";

type Props = {
  orders: Order[];
  handleOrderClick?: (saleorderid: number) => void;
};

const OrderList: React.FC<Props> = ({ orders, handleOrderClick }) => {
  return (
    <List sx={{ width: "100%" }}>
      {orders.map((order) => (
        <ListItemButton
          key={`${order.saleorderid}-${order.name}`}
          onClick={() =>
            handleOrderClick && handleOrderClick(order.saleorderid)
          }
          sx={{ width: "100%", margin: 0, gap: 2 }}
        >
          <ListItemText
            primary={order.name}
            secondary={order.comment}
            sx={{ width: "100%" }}
          />
          <Typography>{currency.format(order.total)}</Typography>
        </ListItemButton>
      ))}
    </List>
  );
};

export default OrderList;
