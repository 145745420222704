import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";
import ArrowDownwardTwoToneIcon from "@mui/icons-material/ArrowDownwardTwoTone";
import { useCallback, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { orderBy } from "../../repository/product";
import ProductCard from "./productCard";
import SearchInput from "./searchInput";
import LoadingProductGrid from "./loadingProductGrid";
import {
  changeOrderBy,
  changeSearchText,
  clear,
  requestProducts,
  toggleOrder,
} from "../../redux/product/productListReducer";
import NoProductGrid from "./noProductsGrid";

type Props = {
  hideFilters?: boolean;
  header?: React.ReactNode;
};

const ProductListGrid: React.FC<Props> = ({
  hideFilters,
  header,
}) => {
  const { products, status, filters, limit } = useAppSelector(
    (state) => state.productList
  );
  const { order, orderby, text } = filters;
  const dispatch = useAppDispatch();

  const getMore = useCallback(async () => {
    await dispatch(requestProducts());
  }, [dispatch]);

  const handleToggleOrder = () => {
    dispatch(toggleOrder());
  };

  const handleChangeOrderBy = (event: SelectChangeEvent<orderBy>) => {
    dispatch(changeOrderBy(event.target.value as orderBy));
  };

  const handleChangeText = (text: string) => {
    dispatch(changeSearchText(text));
  };

  useEffect(() => {
    getMore();
    return () => {
      dispatch(clear());
    };
  }, [dispatch, getMore, order, orderby, text]);

  return (
    <>
      {header}
      {!hideFilters && (
        <Grid container spacing={2} mb={2}>
          <Grid item sm={12} xs={12} md={6}>
            <SearchInput onChangeValue={handleChangeText} defaultValue={text} />
          </Grid>
          {/* <Grid item sm={12} xs={12} md={3}>
            <CategoriesDropdown />
          </Grid> */}
          <Grid item sm={12} xs={12} md={3}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                direction: "row",
                alignItems: "center",
                justifyContent: "stretch",
                flexGrow: 1,
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="orderby-select">Ordenado por</InputLabel>
                <Select
                  fullWidth
                  value={orderby}
                  labelId="orderby-select"
                  label="Ordenado por"
                  onChange={handleChangeOrderBy}
                >
                  <MenuItem value="name">Nombre</MenuItem>
                  <MenuItem value="sku">SKU</MenuItem>
                  <MenuItem value="publicprice">Precio</MenuItem>
                </Select>
              </FormControl>
              <Tooltip title={order === "asc" ? "Ascendente" : "Descendente"}>
                <IconButton onClick={handleToggleOrder}>
                  {order !== "asc" ? (
                    <ArrowUpwardTwoToneIcon />
                  ) : (
                    <ArrowDownwardTwoToneIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      )}
      <InfiniteScroll
        dataLength={products.length}
        next={getMore}
        hasMore={limit > products.length}
        loader={<></>}
      >
        <Grid container spacing={2}>
          {products.map((product, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              key={
                index.toString() + product.productid.toString() + product.sku
              }
            >
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
      {status === "loading" && <LoadingProductGrid />}
      {status === "succeeded" && products.length === 0 && <NoProductGrid />}
    </>
  );
};

export default ProductListGrid;
