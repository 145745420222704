import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MobileStepper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Product from "../../models/product";
import ProductBigCard from "./productBigCard";
import ProductCard from "./productCard";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type Props = {
  products: Product[];
};

const ProductListCarousel: React.FC<Props> = ({ products }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("xl"));
  const matchesmd = useMediaQuery(theme.breakpoints.up("md"));

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = products.length;

  const handleNext = () => {
    var isLast = activeStep === maxSteps - 1;
    setActiveStep((prevActiveStep) => (isLast ? 0 : prevActiveStep + 1));
  };

  const handleBack = () => {
    var isFirst = activeStep === 0;
    setActiveStep((prevActiveStep) =>
      isFirst ? products.length - 1 : prevActiveStep - 1
    );
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {matches && (
          <Box sx={{ position: "absolute", zIndex: 30, left: 20 }}>
            <IconButton size="large" onClick={handleBack}>
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight sx={{ fontSize: 30 }} />
              ) : (
                <KeyboardArrowLeft sx={{ fontSize: 40 }} />
              )}
            </IconButton>
          </Box>
        )}
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          autoPlay={true}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          height={matchesmd ? "50vh" : "55vh"}
        >
          {products.map((product, index) => (
            <Box
              key={index + product.productid.toString() + product.sku}
              sx={{ height: matchesmd ? "50vh" : "60vh", overflow: 'hidden' }}
            >
              {matchesmd ? (
                <ProductBigCard product={product} />
              ) : (
                <ProductCard product={product} />
              )}
            </Box>
          ))}
        </AutoPlaySwipeableViews>
        {matches && (
          <Box sx={{ position: "absolute", zIndex: 30, right: 20 }}>
            <IconButton size="large" onClick={handleNext}>
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft sx={{ fontSize: 30 }} />
              ) : (
                <KeyboardArrowRight sx={{ fontSize: 40 }} />
              )}
            </IconButton>
          </Box>
        )}
      </Box>
      <MobileStepper
        sx={{ marginTop: 1, marginBottom: 1 }}
        steps={maxSteps}
        position="static"
        variant={matchesmd ? "dots" : "text"}
        activeStep={activeStep}
        nextButton={
          matches ? (
            <Box />
          ) : (
            <Button size="small" onClick={handleNext}>
              Siguiente
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          )
        }
        backButton={
          matches ? (
            <Box />
          ) : (
            <Button size="small" onClick={handleBack}>
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Atrás
            </Button>
          )
        }
      />
    </Box>
  );
};

export default ProductListCarousel;
