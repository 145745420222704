"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var regex = /^(\d+)-(\d|K)$/;
function default_1(nit, options) {
    if (options === void 0) { options = {}; }
    var _a = options.limpiar, limpiar = _a === void 0 ? false : _a;
    var res = {
        nit: nit,
        resultado: false
    };
    if (nit != null) {
        var text = nit.toString().toUpperCase();
        if (!text.includes('-')) {
            text = text.substring(0, text.length - 1) + '-' + text.substring(text.length - 1);
        }
        var match = regex.exec(text);
        if (match !== null) {
            var full = match[0], left = match[1], right = match[2];
            var suma_1 = 0;
            left.split('').reverse().forEach(function (c, index) {
                suma_1 += parseInt(c) * (index + 2);
            });
            var resultado = (11 - (suma_1 % 11)) % 11;
            res = {
                resultado: right === (resultado === 10 ? 'K' : resultado.toString()),
                nit: text
            };
        }
    }
    return options.limpiar ? res : res.resultado;
}
exports.default = default_1;
