import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchState from "../fetchStates";
import Order from "../../models/order";
import OrderAPI, { CancelOrder } from "../../repository/order";
import { calculateCart, calculateTotals } from "../../utils/shoppingBagUtils";
import Product from "../../models/product";
import { v4 } from "uuid";

interface ShoppingBagState {
  order?: Order;
  status: fetchState;
  error: string | null;
}

const initialState: ShoppingBagState = {
  order: undefined,
  error: null,
  status: "idle",
};

export const shoppingBagSlice = createSlice({
  name: "shoppingBag",
  initialState,
  reducers: {
    clear: (state) => {
      state.order = undefined;
      state.status = "idle";
      state.error = null;
    },
    loadExistingOrder: (state, action) => {
      state.order = action.payload;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(loadOrder.fulfilled, (state, action) => {
      var orderLoaded: Order = action.payload.order.data;
      orderLoaded.saleorder_detail = action.payload.details.data;
      state.status = "succeeded";
      state.order = orderLoaded;
      state.error = null;
    });
    builder.addCase(loadOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(initOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(initOrder.fulfilled, (state, action) => {
      var orderLoaded: Order = action.payload;
      state.status = "succeeded";
      state.order = orderLoaded;
      state.error = null;
    });
    builder.addCase(initOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(updateOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateOrder.fulfilled, (state, action) => {
      var orderLoaded: Order = action.payload.order;
      orderLoaded.status = action.payload.response.data.data.status;
      state.status = "succeeded";
      state.order = orderLoaded;
      state.error = null;
    });
    builder.addCase(updateOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(addProductToShoppingBag.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(addProductToShoppingBag.fulfilled, (state) => {
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(addProductToShoppingBag.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(removeItem.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(removeItem.fulfilled, (state) => {
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(removeItem.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(emitOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(emitOrder.fulfilled, (state) => {
      state.status = "succeeded";
      state.order = undefined;
      state.error = null;
    });
    builder.addCase(emitOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(cancelOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(cancelOrder.fulfilled, (state) => {
      state.status = "succeeded";
      state.order = undefined;
      state.error = null;
    });
    builder.addCase(cancelOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(deleteOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteOrder.fulfilled, (state) => {
      state.status = "succeeded";
      state.order = undefined;
      state.error = null;
    });
    builder.addCase(deleteOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
  },
});

export const initOrder = createAsyncThunk(
  "shoppingBag/create",
  async (order: Order) => {
    order.saleorder_detail = await calculateCart(order.saleorder_detail);
    order.total = calculateTotals(order.saleorder_detail);
    const response = await new OrderAPI().createOrder(order);
    order.saleorderid = response.data.data.saleorderid;
    order.status = response.data.data.status;
    return order;
  }
);

export const loadOrder = createAsyncThunk(
  "shoppingBag/load",
  async (orderid: number) => {
    const response = await new OrderAPI().getOrderByID(orderid);
    const orderDetailsResponse = await new OrderAPI().getOrderDetails(orderid);
    return { order: response.data, details: orderDetailsResponse.data };
  }
);

export const getOrderDetails = createAsyncThunk(
  "shoppingBag/getDetails",
  async (orderid: number) => {
    const response = await new OrderAPI().getOrderDetails(orderid);
    return response.data;
  }
);

export const emitOrder = createAsyncThunk(
  "shoppingBag/emit",
  async (orderid: number) => {
    const response = await new OrderAPI().emitOrder(orderid);
    return response.data;
  }
);
export const cancelOrder = createAsyncThunk(
  "shoppingBag/cancel",
  async (cancelOrder: CancelOrder) => {
    const response = await new OrderAPI().cancelOrder(cancelOrder);
    return response.data;
  }
);
export const deleteOrder = createAsyncThunk(
  "shoppingBag/delete",
  async (orderid: number) => {
    const response = await new OrderAPI().deleteOrder(orderid);
    return response.data;
  }
);

export const updateOrder = createAsyncThunk(
  "shoppingBag/update",
  async (order: Order) => {
    order.saleorder_detail = await calculateCart(order.saleorder_detail);
    order.total = calculateTotals(order.saleorder_detail);
    const response = await new OrderAPI().updateOrder(order);
    return { order, response };
  }
);

interface AddProduct {
  product: Product;
  quantity: number;
}

export const addProductToShoppingBag = createAsyncThunk(
  "shoppingBag/addProductToShoppingBag",
  async (newProduct: AddProduct, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state: any = getState();
    const { order } = state.shoppingBag as ShoppingBagState;

    if (order) {
      const order_ = { ...order };
      order_.saleorder_detail = [
        ...order.saleorder_detail,
        {
          inv_product: newProduct.product,
          localID: v4(),
          price: newProduct.product.publicprice,
          productid: newProduct.product.productid,
          quantity: newProduct.quantity,
          detid: 0,
          subtotal: newProduct.quantity * newProduct.product.publicprice,
        },
      ];
      await dispatch(updateOrder(order_));
    }
  }
);

export const removeItem = createAsyncThunk(
  "shoppingBag/removeItem",
  async (productid: number, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state: any = getState();
    const { order } = state.shoppingBag as ShoppingBagState;
    if (order && order.status === "D") {
      const order_ = { ...order };
      order_.saleorder_detail = order.saleorder_detail.filter(
        (item) => item.productid !== productid
      );
      await dispatch(updateOrder(order_));
    }
  }
);

export const { clear, loadExistingOrder } = shoppingBagSlice.actions;
export default shoppingBagSlice.reducer;
