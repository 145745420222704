import { IconButton, InputAdornment, TextField } from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../hooks";

type Props = {
  defaultValue?: string;
  onChangeValue: (text: string) => void;
};

const SearchInput: React.FC<Props> = ({ defaultValue, onChangeValue }) => {
  const ref = useRef<null | HTMLDivElement>(null);
  const [inputSearchValue, setInputSearchValue] = useState<string>(
    defaultValue ?? ""
  );
  const searchValue = useDebounce(inputSearchValue, 1000);

  const handleChangeSearchValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInputSearchValue(e.target.value);
  };

  const clearInput = () => {
    setInputSearchValue("");
  };

  useEffect(() => {
    if (searchValue !== defaultValue) onChangeValue(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <TextField
      fullWidth
      hiddenLabel
      id="search"
      placeholder="Ingrese su criterio de búsqueda"
      value={inputSearchValue}
      ref={ref}
      autoComplete='off'
      onChange={handleChangeSearchValue}
      onFocus={(e) => {
        ref.current?.scrollTo({ top: 0, behavior: "smooth" });
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchTwoToneIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton onClick={clearInput}>
            <CancelTwoToneIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default SearchInput;
