import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Page } from "./components/layout";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import {
  AuthenticationStatus,
  validateSession,
} from "./redux/user/authenticationReducer";

const App: React.FC = () => {
  const authenticationState = useAppSelector((state) => state.authentication);
  let location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      authenticationState.authenticationStatus ===
      AuthenticationStatus.authenticated
    ) {
      if (
        location.pathname === "/login" ||
        location.pathname === "/" ||
        location.pathname === "/home"
      ) {
        navigate("/home/products");
      }
    } else if (
      authenticationState.authenticationStatus ===
      AuthenticationStatus.unauntenticated
    ) {
      navigate("/login");
    }
  }, [authenticationState.authenticationStatus, location.pathname, navigate]);

  useEffect(() => {
    dispatch(validateSession());
  }, [dispatch, location.pathname]);

  return (
    <Page>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 1,
        }}
        open={authenticationState.status === "loading"}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Outlet />
    </Page>
  );
};

export default App;
