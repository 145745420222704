import { Container } from "@mui/system";
import React from "react";
import { Outlet } from "react-router-dom";
import { AppBar, BottomNavigation } from "../../components/layout";
import NavigateToTopButton from "../../components/layout/navigateToTopButton";

const Home: React.FC = () => {
  return (
    <>
      <AppBar />
      <Container sx={{ height: "100vh" }}>
        <Outlet />
      </Container>
      <NavigateToTopButton />
      <BottomNavigation />
    </>
  );
};

export default Home;
