import { Paper, Tooltip } from "@mui/material";
import Color from "../../models/color";
import capitalizeFirstLetter from "../../utils/capitalize";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";

type Props = {
  color?: Color;
  selectedID?: number;
  onClick?: (colorid: number) => void;
};

const ProductColor: React.FC<Props> = ({ color, selectedID, onClick }) => {
  if (!color) {
    return null;
  }
  
  return (
    <Tooltip
      title={capitalizeFirstLetter(color.description)}
      key={color.colorcode}
    >
      <Paper
        elevation={!(selectedID === color.colorid) ? 1 : 3}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minWidth: 45,
          maxHeight: 45,
          backgroundColor: color.colorcode,
          borderRadius: 100,
          cursor: "pointer",
        }}
        component="div"
        onClick={() => {
          onClick && onClick(color.colorid);
        }}
      >
        {selectedID === color.colorid && (
          <CheckCircleTwoToneIcon
            fontSize="large"
            sx={{ color: color.colorcode, filter: "invert(1)" }}
          />
        )}
      </Paper>
    </Tooltip>
  );
};

export default ProductColor;
