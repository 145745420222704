import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Portal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { requestLogout } from "../../../redux/user/authenticationReducer";
import { clear } from "../../../redux/shoppingBag/shoppingBagReducer";

const Avatar: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { username } = useAppSelector((state) => state.authentication);
  const dispatch = useAppDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(requestLogout());
    dispatch(clear())
    handleClose();
  };

  return (
    <>
      {!matches ? (
        <IconButton
          id="avatar-menu-button"
          aria-controls={open ? "avatar-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="inherit"
        >
          <AccountCircleTwoToneIcon />
        </IconButton>
      ) : (
        <Button
          id="avatar-menu-button"
          aria-controls={open ? "avatar-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="inherit"
          startIcon={<AccountCircleTwoToneIcon />}
        >
          {username}
        </Button>
      )}
      <Portal>
        <Menu
          id="avatar-menu"
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          aria-labelledby="avatar-menu-button"
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleLogout} color="red">
            Cerrar Sesión
          </MenuItem>
        </Menu>
      </Portal>
    </>
  );
};

export default Avatar;
