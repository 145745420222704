import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchState from "../fetchStates";
import Order from "../../models/order";
import OrderAPI, { OrderAPIParams } from "../../repository/order";
import moment from "moment";

interface OrdersState {
  orders: Order[];
  status: fetchState;
  error: string | null;
}

const initialState: OrdersState = {
  orders: [],
  error: null,
  status: "idle",
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clean: (state) => {
      state.orders = [];
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers(builder) {
    builder.addCase(getOrders.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getOrders.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.orders = action.payload.data;
      state.orders.map((order) => ({
        ...order,
        createdAt: moment(order.createdAt).toDate(),
      }));
      state.error = null;
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
  },
});

export const getOrders = createAsyncThunk(
  "orders/get",
  async (orderAPIParams: OrderAPIParams) => {
    const response = await new OrderAPI().getOrders({
      ...orderAPIParams,
      orderby: "documentno",
      order: "DESC",
    });
    return response.data;
  }
);

export const { clean } = ordersSlice.actions;
export default ordersSlice.reducer;
