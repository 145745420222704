import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OrderCard from "../../components/order/orderCard";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getOrders, clean } from "../../redux/order/ordersReducer";
import NoOrders from "../../components/order/noOrders";
// import OrderFilters from "../../components/order/orderFilters";

const HomeOrders: React.FC = () => {
  const dispatch = useAppDispatch();
  const { orders, status } = useAppSelector((state) => state.orders);
  const navigate = useNavigate();

  const getMore = async () => {
    await dispatch(getOrders({}));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getMore();
    return () => {
      dispatch(clean());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleNavigate = (orderid: number) => {
    navigate(`/order/${orderid}`);
  };

  return orders.length === 0 && status === "succeeded" ? (
    <NoOrders onSubmitNewOrder={getMore} />
  ) : (
    <Box sx={{ paddingY: 12 }}>
      <Box
        sx={{
          width: "100%",
          display: orders.length === 0 ? "none" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <OrderFilters /> */}
        <Typography variant="h6">Pedidos: {orders.length}</Typography>
      </Box>
      {(status === "succeeded"
        ? orders
        : [
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
          ]
      ).map((order, index) => (
        <OrderCard
          order={typeof order === "number" ? undefined : order!}
          loading={status === "loading"}
          onClick={handleNavigate}
        />
      ))}
    </Box>
  );
};

export default HomeOrders;
