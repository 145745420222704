import { Stack, Typography } from "@mui/material";
import Color from "../../models/color";
import ProductColor from "./productColor";

type Props = {
  selectedID?: number;
  colors: Color[];
  onClick: (id: number) => void;
};

const ProductColors: React.FC<Props> = ({ colors, selectedID, onClick }) => {
  if (colors.length === 0) {
    return null;
  }

  return (
    <>
      <Typography variant="subtitle1">Colores</Typography>
      <Stack
        direction="row"
        spacing={1}
        overflow="auto"
        minHeight={50}
        justifyContent="flex-start  "
        alignContent="center"
      >
        {colors.map((color) => (
          <ProductColor
            color={color}
            onClick={onClick}
            selectedID={selectedID}
          />
        ))}
      </Stack>
    </>
  );
};

export default ProductColors;
