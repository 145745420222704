import { useNavigate } from "react-router-dom";
import AppBarNavigationButtons from "./appbarNavigationButton";
import BottomNavigationActionButton from "./bottomNavigationActionButton";
import NavigationButtonProps from "./props";

type Props = {
  type: "bottom" | "appbar";
  buttonProps: NavigationButtonProps;
};

const NavigationButton: React.FC<Props> = ({ type, buttonProps }) => {
  var navigate = useNavigate();

  const handleNavigate = () => navigate(buttonProps.route);

  if (type === "appbar") {
    return (
      <AppBarNavigationButtons {...buttonProps} onClick={handleNavigate} />
    );
  }

  
  return (
    <BottomNavigationActionButton {...buttonProps} onClick={handleNavigate} />
  );
};

export default NavigationButton;
