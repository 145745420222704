import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../hooks";
import { removeItem as removeCartItem } from "../../../redux/shoppingBag/shoppingBagReducer";
import { removeItem as removeOrderItem } from "../../../redux/order/orderReducer";
import MDOrderDetailItem from "./mdOrderDetailItem";
import OderItemProps from "./props";
import SMOrderDetailItem from "./smOrderDetailItem";
import { useState } from "react";
import RemoveItemOrderModal from "../removeItemOrderModal";

type Props = OderItemProps & {};

const OrderDetailItem: React.FC<Props> = ({
  orderItem,
  isEditing,
  isShoppingBag,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const handleProductClick = () => {
    navigate(`/product/${orderItem.productid}`);
  };

  const handleRemoveItem = () => {
    if (isShoppingBag) {
      dispatch(removeCartItem(orderItem.productid));
    } else {
      dispatch(removeOrderItem(orderItem.productid));
    }
    handleCloseRemoveItemModal();
  };

  const handleOpenRemoveItemModal = () => {
    setOpen(true);
  };
  const handleCloseRemoveItemModal = () => {
    setOpen(false);
  };

  return (
    <>
      {isSm ? (
        <MDOrderDetailItem
          orderItem={orderItem}
          isLg={isLg}
          isEditing={isEditing}
          onProductClick={handleProductClick}
          onRemoveItem={handleOpenRemoveItemModal}
        />
      ) : (
        <SMOrderDetailItem
          orderItem={orderItem}
          isEditing={isEditing}
          onProductClick={handleProductClick}
          onRemoveItem={handleOpenRemoveItemModal}
        />
      )}
      <RemoveItemOrderModal
        product={orderItem.inv_product}
        open={open}
        onConfirm={handleRemoveItem}
        onCancel={handleCloseRemoveItemModal}
      />
    </>
  );
};

export default OrderDetailItem;
