import { Box, CardMedia } from "@mui/material";
import { useState } from "react";
import ImageNotFound from "./imageNotFound";

type Props = {
  srcImage?: string;
  alt: string;
  height?: number | string;
  width?: number | string;
};

const Image: React.FC<Props> = ({ alt, srcImage, height, width }) => {
  const [hasError, setHasError] = useState(false);

  return (
    <Box sx={{ height, width }}>
      {srcImage && !hasError ? (
        <CardMedia
          component="img"
          image={srcImage}
          alt={alt}
          onError={() => {
            setHasError(true);
          }}
          sx={{ objectFit: "cover", height, width }}
        />
      ) : (
        <ImageNotFound />
      )}
    </Box>
  );
};

export default Image;
