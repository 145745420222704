import { Box, Divider, Button, Tooltip } from "@mui/material";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import ArrowBackTwoToneIcon from "@mui/icons-material/ArrowBackTwoTone";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks";
import Order from "../../models/order";
import { emitOrder } from "../../redux/shoppingBag/shoppingBagReducer";
import ShoppingBagHeader from "./shoppingBagHeader";
import ShoppingBagDetails from "./shoppingBagDetails";

type Props = {
  order: Order;
};

const ShoppingBagData: React.FC<Props> = ({ order }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleEmit = async () => {
    try {
      if (order) {
        enqueueSnackbar("Emitiendo pedido", { variant: "info" });
        const data = await dispatch(emitOrder(order.saleorderid));
        closeSnackbar();
        enqueueSnackbar(
          `Pedido #${data.payload.data.documentno} emitido correctamente`,
          {
            variant: "success",
          }
        );
        navigate(`/order/${order.saleorderid}`);
      }
    } catch (error) {
      closeSnackbar();
      enqueueSnackbar("Ocurrió un error", { variant: "error" });
    }
  };
  const handleCancel = () => {
    if (order) navigate("/home/products");
  };

  return (
    <Box sx={{ paddingY: 12 }}>
      <ShoppingBagHeader order={order} />
      <ShoppingBagDetails items={order.saleorder_detail} total={order.total} />
      <Divider sx={{ marginY: 2 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 4,
          paddingBottom: 6,
        }}
      >
        <Button startIcon={<ArrowBackTwoToneIcon />} onClick={handleCancel}>
          continuar comprando
        </Button>
        <Tooltip
          title={
            parseInt(order.total.toString()) === 0
              ? "No puedes emitir este pedido por que al menos debes agregar un producto"
              : ""
          }
        >
          <span>
            <Button
              type="button"
              variant="contained"
              size="large"
              startIcon={<CheckCircleTwoToneIcon />}
              onClick={handleEmit}
              color="success"
              disabled={!(order.total > 0)}
            >
              Emitir
            </Button>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ShoppingBagData;
