import { ErrorOutlineTwoTone } from "@mui/icons-material";
import { Box, Button, Container, Typography } from "@mui/material";
import { useNavigate, useRouteError } from "react-router-dom";
import { Page } from "../components/layout";

const Error: React.FC = () => {
  const error = useRouteError() as any;
  const navigate = useNavigate();

  const handleGoBack = () => navigate(-1);

  return (
    <Page>
      <Container
        sx={{
          paddingBottom: 20,
          paddingTop: 9.4,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ErrorOutlineTwoTone sx={{ fontSize: 80 }} />
        <Typography variant="h2">Ups!</Typography>
        <Typography variant="body1">Ha ocurrido un error al buscar</Typography>
        <Typography variant="caption">
          {error?.status} - {error?.statusText ?? ""}
        </Typography>
        <Box sx={{ marginTop: 2 }}>
          <Button LinkComponent="a" onClick={handleGoBack}>
            Regresar
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default Error;
