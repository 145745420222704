import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";

const ProductSkeleton: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} xl={5}>
        {/* For carousel */}
        <Skeleton variant="rectangular" height={510} />
      </Grid>
      <Grid item xs={12} sm={6} xl={7}>
        {/* Price */}
        <Typography
          variant="h3"
          textAlign="end"
          sx={{
            position: "absolute",
            right: 24,
          }}
        >
          <Skeleton width={100} />
        </Typography>

        {/* SKU */}
        <Skeleton width="40%" sx={{ marginTop: 3 }} />

        {/* title */}
        <Typography variant="h4" sx={{ marginTop: 0.1 }}>
          <Skeleton />
          <Skeleton width="30%" />
        </Typography>

        <Box sx={{ marginTop: 1 }}>
          {/* description */}
          <Skeleton />
          <Skeleton />
          <Skeleton width="40%" />
        </Box>

        {/* For colors */}
        <Box sx={{ marginTop: 1 }} >
        <Skeleton width={150} height={70} />
        </Box>

        <Box sx={{ height: 10 }} />
        {/* For colors */}
        <Skeleton width={150} />
        <Stack direction="row" spacing={1}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="circular" width={40} height={40} />
        </Stack>

        <Box sx={{ height: 20 }} />

        {/* For availability */}
        <Typography variant="subtitle1" maxWidth={150}>
          <Skeleton />
        </Typography>

        {/* For inputs to add to cart */}
        <Skeleton variant="rounded" width={190} height={40} />
        {/* For inputs to add to cart button */}
        <Box sx={{ height: 10 }} />
        <Skeleton variant="rounded" width={210} height={40} />
      </Grid>
    </Grid>
  );
};

export default ProductSkeleton;
