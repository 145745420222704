import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { IconButton } from "@mui/material";

type Props = {
  onDelete: () => void;
};

const DeleteItemButton: React.FC<Props> = ({ onDelete }) => {
  return (
    <IconButton type="button" color="error" onClick={onDelete}>
      <DeleteTwoToneIcon />
    </IconButton>
  );
};

export default DeleteItemButton;
