import * as Yup from "yup";
import validarNIT from "validar-nit-gt";
import { BaseOrder } from "../../../models/order";

export const initialValues: BaseOrder = {
  name: "",
  phonenumber: "",
  address: "",
  documentid: "",
  taxid: "",
  businessname: "",
  total: 0,
  emit: false,
  comment: "",
  saleorder_detail: [],
};

export const validations = Yup.object().shape({
  name: Yup.string()
    .max(100, "Debe de ser un nombre menor a 100 letras.")
    .min(2, "Debe de ser un nombre válido.")
    .required("Ingrese a nombre de quien saldrá el pedido."),
  taxid: Yup.string().test("val", "Debe ser un NIT válido.", (val) => {
    if (
      val === "C/F" ||
      val === "c/f" ||
      val === "C/f" ||
      val === "c/F" ||
      val === "CF" ||
      val === "cf" ||
      val === "Cf" ||
      val === "cF"
    )
      return true;
    if (val) return validarNIT(val);
    return true;
  }),
  businessname: Yup.string(),
  phonenumber: Yup.string()
    .matches(/^[0-9]{4}?[-]?[0-9]{4}$/, "Debe de ingresar un teléfono válido.")
    .required("Ingrese el teléfono del cliente."),
  documentid: Yup.number().test(
    "len",
    "El DPI debe de tener 13 dígitos",
    (val) => {
      if (val) return val?.toString().length === 13;
      return true;
    }
  ),
  address: Yup.string()
    .min(4, "Debe de ingresar una dirección válida.")
    .required("Ingrese la dirección del cliente."),
  total: Yup.number(),
  emit: Yup.boolean(),
  comment: Yup.string().min(2, "Debe de ser un comentario descriptivo."),
  saleorder_detail: Yup.array().of(Yup.object()),
});
