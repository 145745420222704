import { IconButton, Paper, Portal } from "@mui/material";
import ArrowUpwardTwoToneIcon from "@mui/icons-material/ArrowUpwardTwoTone";

const NavigateToTopButton: React.FC = () => {
  const handlePress = () => {
    window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
  };

  return (
    <Portal>
      <Paper sx={{ position: "fixed", right: 20, bottom: 100, zIndex: 100 }}>
        <IconButton onClick={handlePress} size="large">
          <ArrowUpwardTwoToneIcon />
        </IconButton>
      </Paper>
    </Portal>
  );
};

export default NavigateToTopButton;
