import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import OrderForm from "../order/form/orderForm";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getOrders, clean } from "../../redux/order/ordersReducer";
import OrderList from "../order/orderList";
import {
  initOrder,
  loadOrder,
} from "../../redux/shoppingBag/shoppingBagReducer";
import LoadingOrder from "../order/loadingOrder";

const NoSelectedOrder: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const dispatch = useAppDispatch();
  const { orders, status } = useAppSelector((state) => state.orders);

  useEffect(() => {
    dispatch(getOrders({ status: "D" }));

    return () => {
      dispatch(clean());
    };
  }, [dispatch]);

  const handleOrderClick = async (orderid: number) => {
    await dispatch(loadOrder(orderid));
  };

  const handleSubmitOrder = async (values: any) => {
    await dispatch(initOrder(values));
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        paddingY: 12,
      }}
    >
      <Box mb={4}>
        <ShoppingBagTwoToneIcon sx={{ fontSize: 100 }} />
        <Typography variant="h4" component="h2">
          No se ha agregado un pedido
        </Typography>
        <Typography variant="caption">
          Selecciona la opción que necesitas
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid
          item
          sm={12}
          md={status === "succeeded" && orders.length && 5}
          sx={{ width: "100%" }}
        >
          <Typography variant="h6">Puedes crear un nuevo pedido</Typography>
          <OrderForm onSubmit={handleSubmitOrder} />
        </Grid>
        <Grid
          md={2}
          sm={12}
          display={
            status === "succeeded" && orders.length > 0 ? "flex" : "none"
          }
          justifyContent="center"
          alignItems="center"
        >
          <Divider
            variant="fullWidth"
            orientation={matches ? "vertical" : "horizontal"}
          />
        </Grid>
        {status === "loading" ? (
          <Grid
            item
            sm={12}
            md={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <LoadingOrder />
          </Grid>
        ) : (
          orders.length > 0 && (
            <Grid item sm={12} md={5} sx={{ width: "100%" }}>
              <Typography variant="h6">Seleccionar una orden</Typography>
              <OrderList orders={orders} handleOrderClick={handleOrderClick} />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};

export default NoSelectedOrder;
