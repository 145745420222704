import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import capitalizeFirstLetter from "../../../utils/capitalize";
import Image from "../../images/image";
import DeleteItemButton from "./deleteButton";
import Props from "./props";
import currency from "../../../utils/currency";

const SMOrderDetailItem: React.FC<Props> = ({
  orderItem,
  isEditing = false,
  onProductClick,
  onRemoveItem,
}) => {
  const { inv_product, price, quantity, subtotal } = orderItem;
  const cursor = onProductClick ? "pointer" : "auto";

  const handleDelete = () => {
    onRemoveItem && onRemoveItem();
  };

  return (
    <Card sx={{ height: 390 }} variant="outlined">
      <Box onClick={onProductClick} sx={{ cursor }}>
        <Image
          alt="sfasd"
          srcImage={inv_product?.inv_productimages?.at(0)?.url ?? ""}
          height={150}
        />
      </Box>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            cursor,
          }}
        >
          <Typography variant="caption">{inv_product.sku}</Typography>
          {isEditing && <DeleteItemButton onDelete={handleDelete} />}
        </Box>
        <Typography
          component="div"
          gutterBottom
          variant="h5"
          onClick={onProductClick}
          sx={{
            width: "100%",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: 0,
            cursor,
          }}
        >
          {capitalizeFirstLetter(inv_product.name)}
        </Typography>

        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            width: "100%",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {capitalizeFirstLetter(inv_product.description)}
        </Typography>
        <Divider sx={{ marginY: 2 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box>
            <Typography>Precio: {currency.format(price)}</Typography>
            <Typography>Cantidad: {quantity}</Typography>
          </Box>
          <Typography variant="h6">{currency.format(subtotal)}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SMOrderDetailItem;
