import { Typography } from "@mui/material";
import { orderStateToString } from "../../utils/orderStateToString";
import { OrderStatus as OrderStatusModel } from "../../models/order";

type Props = {
  status: OrderStatusModel;
};

const OrderStatus: React.FC<Props> = ({ status }) => {
  var color = "";

  if (status === "I") color = "green";
  else if (status === "D") color = "#b6b61e";
  else if (status === "C") color = "red";

  return <Typography color={color}>{orderStateToString(status)}</Typography>;
};

export default OrderStatus;
