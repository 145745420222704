import { Box, Typography } from "@mui/material";
import SentimentDissatisfiedTwoToneIcon from "@mui/icons-material/SentimentDissatisfiedTwoTone";
import OrderForm from "./form/orderForm";
import { useSnackbar } from "notistack";
import { useAppDispatch } from "../../hooks";
import { initOrder } from "../../redux/shoppingBag/shoppingBagReducer";

type Props = {
  onSubmitNewOrder: () => void;
};

const NoOrders: React.FC<Props> = ({ onSubmitNewOrder }) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleSubmitOrder = async (values: any) => {
    enqueueSnackbar("Se esta creando su nueva orden");
    await dispatch(initOrder(values));
    closeSnackbar();
    enqueueSnackbar("Se ha creado una nueva orden", { variant: "success" });
    onSubmitNewOrder();
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SentimentDissatisfiedTwoToneIcon sx={{ fontSize: 100 }} />
      <Typography variant="h5">No hay pedidos existentes</Typography>
      <Typography variant="caption">
        Puedes registrar tu primer pedido ingresando la siguiente información
      </Typography>
      <OrderForm onSubmit={handleSubmitOrder} />
    </Box>
  );
};

export default NoOrders;
