import {
  Box,
  Card,
  Divider,
  Grid,
  List,
  Skeleton,
  Typography,
} from "@mui/material";
import OrderItem from "../../models/orderItem";
import OrderDetailItem from "./orderDetailItem/orderDetailItem";

type Props = {
  title?: string;
  detail: OrderItem[];
  enableEdit: boolean;
  isShoppingBag?: boolean;
  isLoading?: boolean;
};

const OrderDetailList: React.FC<Props> = ({
  detail,
  enableEdit = false,
  isShoppingBag = false,
  isLoading = false,
  title = "Detalles",
}) => {
  return (
    <>
      <Divider sx={{ marginY: 2 }} />
      <Typography variant="h5">{title}</Typography>
      <List>
        {(isLoading
          ? [undefined, undefined, undefined, undefined, undefined]
          : detail
        ).map((detail, index) => (
          <Box
            sx={{ marginBottom: 2 }}
            key={index + (detail?.detid ?? "").toString()}
          >
            {detail && !isLoading ? (
              <OrderDetailItem
                orderItem={detail}
                isEditing={enableEdit}
                isShoppingBag={isShoppingBag}
              />
            ) : (
              <Card key={index + (detail?.detid ?? "").toString()}>
                <Grid container>
                  <Grid item sm={12} md={4}>
                    <Skeleton variant="rectangular" height={280} width="100%" />
                  </Grid>
                  <Grid item sm={12} md={8} sx={{ padding: 2 }}>
                    <Skeleton width={80} />
                    <Typography variant="h5">
                      <Skeleton width="100%" sx={{ maxWidth: 100 }} />
                    </Typography>
                    <Typography variant="body2">
                      <Skeleton width={120} />
                    </Typography>
                    <Skeleton width={120} />
                    <Box
                      sx={{
                        height: "50%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                      }}
                    >
                      <Skeleton width={80} />
                      <Typography variant="h6">
                        <Skeleton width={120} />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Box>
        ))}
      </List>
    </>
  );
};

export default OrderDetailList;
