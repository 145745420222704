import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import moment from "moment";
import Order from "../../models/order";
import OrderCardSkeleton from "./orderCardSkeleton";
import OrderStatus from "./orderStatus";
import currency from "../../utils/currency";

type Props = {
  order?: Order;
  loading: boolean;
  onClick?: (orderid: number) => void;
};

const OrderCard: React.FC<Props> = ({ order, loading, onClick }) => {
  const handleClick = () => {
    onClick && order && onClick(order.saleorderid);
  };

  if (loading || !order) {
    return <OrderCardSkeleton />;
  }

  return (
    <Card sx={{ marginBottom: 1 }} variant="outlined">
      <CardContent
        sx={{ display: "flex", flexDirection: "column", position: "relative" }}
      >
        <Typography variant="h6">Pedido #{order.documentno}</Typography>
        <OrderStatus status={order.status} />
        <Typography variant="h5">Cliente: {order.name}</Typography>
        <Typography variant="body2">
          Teléfono: {order.phonenumber ?? "-"}
        </Typography>
        <Typography variant="body2">
          DPI: {order.documentid ?? "-"}
        </Typography>
        <Typography variant="body2">{order.comment}</Typography>
        <Typography variant="h6">{currency.format(order.total)}</Typography>
        <Typography color="text.secondary" variant="caption">
          Creado: {moment(order.createdAt).format("DD/MM/yyyy HH:mm")}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          Actualizado:{" "}
          {order.updatedAt ? moment(order.updatedAt).format("DD/MM/yyyy HH:mm") : "-"}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleClick}>
          Ver más
        </Button>
      </CardActions>
    </Card>
  );
};

export default OrderCard;
