import MaterialBottomNavigation from "@mui/material/BottomNavigation";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import buttons from "./navigationButtons";
import NavigationButton from "./navigationButtons/navigationButton";

const BottomNavigation: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  if (matches) {
    return null;
  }

  return (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 30 }}
        elevation={3}
      >
        <MaterialBottomNavigation>
          {buttons
            .filter((e) => !e.hideInBottomNavigationBar)
            .map((e) => (
              <NavigationButton key={e.route} buttonProps={e} type="bottom" />
            ))}
        </MaterialBottomNavigation>
      </Paper>
    </>
  );
};
export default BottomNavigation;
