import OrderItem from "../models/orderItem";

export const calculateCart = async (
  details: OrderItem[]
): Promise<OrderItem[]> => {
  const newDetails: OrderItem[] = [];
  const filteredDetails = details.filter(
    (value, index, arr) =>
      arr.map((e) => e.productid).indexOf(value.productid) === index
  );

  for await (const filteredItem of filteredDetails) {
    const item = { ...filteredItem };
    const relatedProducts = details.filter(
      (e) => e.productid === item.productid
    );
    const quantity = relatedProducts
      .map((e) => Number(e.quantity))
      .reduce((total, value) => total + value);
    item.quantity = quantity;
    item.subtotal = quantity * item.price;
    newDetails.push(item);
  }

  return newDetails;
};

export const calculateTotals = (details: OrderItem[]): number => {
  try {
    return details
      .map((e) => Number(e.subtotal))
      .reduce((total, details) => total + details);
  } catch (error) {
    return 0;
  }
};
