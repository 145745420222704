import { createBrowserRouter } from "react-router-dom";
import App from "./app";
import Home from "./pages/home/home";
import HomeProducts from "./pages/home/homeProducts";
import Login from "./pages/login";
import Product from "./pages/product";
import Error from "./pages/error";
import HomeOrders from "./pages/home/homeOrders";
import Order from "./pages/order";
import ShoppingBag from "./pages/shoppingBag";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "home",
        element: <Home />,
        children: [
          {
            path: "products",
            element: <HomeProducts />,
          },
          {
            path: "orders",
            element: <HomeOrders />,
          },
        ],
      },
      {
        path: "product/:productid",
        element: <Product />,
      },
      {
        path: "order/:orderid",
        element: <Order />,
      },
      {
        path: "shoppingBag",
        element: <ShoppingBag />,
      },
    ],
  },
]);

export default router;
