import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import jwtDecode from "jwt-decode";
import AuthenticationAPI from "../../repository/authentication";
import { LoginFormSchema } from "../../components/user/login/form/form_schema";
import fetchState from "../fetchStates";
import moment from "moment";

interface AuthenticationState {
  authenticationStatus: AuthenticationStatus;
  username: string;
  status: fetchState;
  error: string | null;
}

export enum AuthenticationStatus {
  unauntenticated,
  authenticated,
  unknown,
}

const initialState: AuthenticationState = {
  authenticationStatus: AuthenticationStatus.unknown,
  error: null,
  status: "idle",
  username: "",
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    validateSession: (state) => {
      var token = localStorage.getItem("token");
      var expiration = localStorage.getItem("expiration");
      var username = localStorage.getItem("username");

      if (token && expiration && username) {
        if (moment.unix(parseInt(expiration)).isSameOrAfter(moment())) {
          state.authenticationStatus = AuthenticationStatus.authenticated;
          state.username = username;
          return;
        }
      }
      localStorage.clear();
      state.authenticationStatus = AuthenticationStatus.unauntenticated;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(requestLogin.pending, (state) => {
        state.status = "loading";
        state.authenticationStatus = AuthenticationStatus.unauntenticated;
      })
      .addCase(requestLogin.fulfilled, (state, action) => {
        const token = action.payload.data.data.token;
        var tokenData = jwtDecode(token) as any;
        localStorage.setItem("token", token);
        localStorage.setItem("expiration", tokenData.exp.toString());
        localStorage.setItem("username", action.payload.username);
        state.status = "succeeded";
        state.username = action.payload.username;
        state.authenticationStatus = AuthenticationStatus.authenticated;
      })
      .addCase(requestLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error =
          action.error.code === "ERR_NETWORK"
            ? "Usuario y/o contraseña inválidos"
            : action.error.code ?? "something wrong";
        state.authenticationStatus = AuthenticationStatus.unauntenticated;
      })
      .addCase(requestLogout.pending, (state) => {
        state.status = "loading";
        state.authenticationStatus = AuthenticationStatus.unauntenticated;
      })
      .addCase(requestLogout.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.authenticationStatus = AuthenticationStatus.unauntenticated;
      })
      .addCase(requestLogout.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message ?? "something wrong";
        state.authenticationStatus = AuthenticationStatus.unauntenticated;
      });
  },
});

export const requestLogin = createAsyncThunk(
  "authentication/login",
  async (values: LoginFormSchema) => {
    const response = await new AuthenticationAPI().tryLogin(
      values.username,
      values.password
    );
    return { data: response, username: values.username };
  }
);

export const requestLogout = createAsyncThunk(
  "authentication/logout",
  async () => {
    await new AuthenticationAPI().logout();
    localStorage.clear();
  }
);

export const { validateSession } = authenticationSlice.actions;
export default authenticationSlice.reducer;
