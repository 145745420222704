import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import ProductListGrid from "../../components/product/productListGrid";
import ProductListCarousel from "../../components/product/productListCarousel";
import { Box, Divider } from "@mui/material";
import { useEffect } from "react";
import {
  requestStarredProducts,
  clear as clearStarredProducts,
} from "../../redux/product/starredProductListReducer";

const HomeProducts: React.FC = () => {
  const dispatch = useAppDispatch();
  const productStarredState = useAppSelector(
    (state) => state.starredProductList
  );

  useEffect(() => {
    dispatch(requestStarredProducts());
    return () => {
      dispatch(clearStarredProducts());
    };
  }, [dispatch]);

  return (
    <Box sx={{ paddingY: 12 }} component="div" id="homeproducts">
      {productStarredState.products.length > 0 && (
        <Box mb={2}>
          <ProductListCarousel products={productStarredState.products} />
          <Divider />
        </Box>
      )}
      <ProductListGrid  />
    </Box>
  );
};

export default HomeProducts;
