import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { Backdrop, Box, Button, MobileStepper, useTheme } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import ImageViewer from "react-simple-image-viewer";

import ImageModel from "../../models/image";
import Image from "../images/image";
import ImageNotFound from "../images/imageNotFound";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

type Props = {
  images: ImageModel[];
};

const ProductImagesCarousel: React.FC<Props> = ({ images }) => {
  const theme = useTheme();
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  return (
    <Box>
      {images.length > 0 ? (
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          autoPlay={!isViewerOpen}
        >
          {images.map((e) => (
            <Box key={e.imageid} component="div" onClick={openImageViewer}>
              <Image srcImage={e.url} alt="imágen de joyas" height={500} />
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      ) : (
        <ImageNotFound />
      )}
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Siguiente
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Atrás
          </Button>
        }
      />
      {isViewerOpen && (
        <Backdrop component="div" open={isViewerOpen}>
          <ImageViewer
            src={images.map((image) => image.url)}
            currentIndex={activeStep}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            leftArrowComponent={
              <KeyboardArrowLeftIcon sx={{ color: "white", fontSize: 50 }} />
            }
            rightArrowComponent={
              <KeyboardArrowRightIcon sx={{ color: "white", fontSize: 50 }} />
            }
          />
        </Backdrop>
      )}
    </Box>
  );
};

export default ProductImagesCarousel;
