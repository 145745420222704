import { Box, Button, Typography } from "@mui/material";
import SentimentDissatisfiedTwoToneIcon from "@mui/icons-material/SentimentDissatisfiedTwoTone";
import { useNavigate } from "react-router-dom";

type Props = {
  error: string;
};

const ProductNotFound: React.FC<Props> = ({ error }) => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <SentimentDissatisfiedTwoToneIcon sx={{ fontSize: 100 }} />
      <Typography variant="h4">{error}</Typography>
      <Button variant="contained" onClick={handleGoBack}>
        Regresar
      </Button>
    </Box>
  );
};

export default ProductNotFound;
