import { Box, CircularProgress, Typography } from "@mui/material";

type Props = {};

const LoadingProductGrid: React.FC<Props> = () => {
  return (
    <Box
      height="120px"
      width="100%"
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
    >
      <CircularProgress />
      <Typography variant="h6">Buscando productos</Typography>
    </Box>
  );
};

export default LoadingProductGrid;