import { Box, Button, IconButton, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { useCallback, useEffect, useState } from "react";
import Product from "../../models/product";
import { useAppDispatch, useAppSelector } from "../../hooks";
import OrdersModal from "./ordersModal";
import { addProductToShoppingBag } from "../../redux/shoppingBag/shoppingBagReducer";
import { useSnackbar } from "notistack";
import capitalizeFirstLetter from "../../utils/capitalize";

type Props = {
  balance: number;
  product?: Product;
};

const AddToOrder: React.FC<Props> = ({ balance, product }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [inputQuantityValue, setInputQuantityValue] = useState<string>("1");
  const [hasError, setHasError] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { order } = useAppSelector((state) => state.shoppingBag);

  const validateQuantity = useCallback(
    (quantity: number): string => {
      var returnValue = quantity.toString();
      var newQuantity = Number(quantity);
      if (typeof newQuantity === "number" && newQuantity > 0) {
        setHasError(false);
        if (quantity > balance) {
          returnValue = parseInt(balance.toString()).toString();
        }
      } else {
        setHasError(true);
        returnValue = "";
      }
      return returnValue;
    },
    [balance]
  );

  const handleChange = (quantity: string) => {
    var newQuantity = Number(quantity);
    const _quantity = validateQuantity(newQuantity);
    setInputQuantityValue(_quantity);
  };

  const handlePlus = () => {
    const quantity_ = Number(inputQuantityValue) ?? 0;
    const _quantity = validateQuantity(quantity_ + 1);
    setInputQuantityValue(_quantity);
  };

  const handleMinus = () => {
    const quantity_ = Number(inputQuantityValue) ?? 0;
    const _quantity = validateQuantity(quantity_ - 1);
    setInputQuantityValue(_quantity);
  };

  const handleAddProduct = () => {
    if (!order) {
      setModalOpen(true);
    }
    if (product && order) {
      dispatch(
        addProductToShoppingBag({
          product,
          quantity: Number(inputQuantityValue),
        })
      );
      enqueueSnackbar(
        `Se ha agregado el producto: ${capitalizeFirstLetter(
          product.name
        ).substring(0, 20)}${product.name.length > 20 ? "..." : ""} a la cesta`
      );
    }
  };

  useEffect(() => {
    setInputQuantityValue((prev) => validateQuantity(parseInt(prev)));
  }, [balance, validateQuantity]);

  return (
    <>
      <Stack direction="row" spacing={1} mt={1}>
        <IconButton
          onClick={handleMinus}
          disabled={!(balance > 0)}
          sx={{ width: 55 }}
        >
          <RemoveIcon />
        </IconButton>
        <TextField
          sx={{ width: 120 }}
          id="Quantity"
          label="Cantidad"
          variant="outlined"
          value={inputQuantityValue}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
          disabled={!(balance > 0)}
        />
        <IconButton
          onClick={handlePlus}
          disabled={
            !(balance > 0) ||
            parseInt(inputQuantityValue) === parseInt(balance.toString())
          }
          sx={{ width: 55 }}
        >
          <AddIcon />
        </IconButton>
      </Stack>
      <Box sx={{ marginTop: 1 }}>
        <Button
          variant="contained"
          size="large"
          startIcon={<ShoppingBagIcon />}
          disabled={!(balance > 0) || hasError}
          onClick={handleAddProduct}
        >
          Añadir a la cesta
        </Button>
      </Box>
      <OrdersModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        quantity={Number(inputQuantityValue)}
        product={product}
      />
    </>
  );
};

export default AddToOrder;
