import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchState from "../fetchStates";
import Order from "../../models/order";
import OrderAPI from "../../repository/order";
import { calculateCart, calculateTotals } from "../../utils/shoppingBagUtils";

interface OrderState {
  order?: Order;
  status: fetchState;
  error: string | null;
}

const initialState: OrderState = {
  order: undefined,
  error: null,
  status: "idle",
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clear: (state) => {
      state.order = undefined;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(loadOrder.fulfilled, (state, action) => {
      var orderLoaded: Order = action.payload.order.data;
      orderLoaded.saleorder_detail = action.payload.details.data;
      state.status = "succeeded";
      state.order = orderLoaded;
      state.error = null;
    });
    builder.addCase(loadOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(updateOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateOrder.fulfilled, (state, action) => {
      var orderLoaded: Order = action.payload.order;
      orderLoaded.status = action.payload.response.data.data.status;
      state.status = "succeeded";
      state.order = orderLoaded;
      state.error = null;
    });
    builder.addCase(updateOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(removeItem.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(removeItem.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(removeItem.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
    builder.addCase(emitOrder.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(emitOrder.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(emitOrder.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.error.message ?? "something wrong";
    });
  },
});

export const loadOrder = createAsyncThunk(
  "order/load",
  async (orderid: number) => {
    const response = await new OrderAPI().getOrderByID(orderid);
    const orderDetailsResponse = await new OrderAPI().getOrderDetails(orderid);
    return { order: response.data, details: orderDetailsResponse.data };
  }
);

export const updateOrder = createAsyncThunk(
  "order/update",
  async (order: Order) => {
    order.saleorder_detail = await calculateCart(order.saleorder_detail);
    order.total = calculateTotals(order.saleorder_detail);
    const response = await new OrderAPI().updateOrder(order);
    return { order, response };
  }
);

export const removeItem = createAsyncThunk(
  "order/removeItem",
  async (productid: number, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const state: any = getState();
    const { order } = state.order as OrderState;

    if (order && order.status === "D") {
      const order_ = { ...order };
      order_.saleorder_detail = order.saleorder_detail.filter(
        (item) => item.productid !== productid
      );
      dispatch(updateOrder(order_));
    }
  }
);

export const emitOrder = createAsyncThunk(
  "order/emit",
  async (orderid: number) => {
    const response = await new OrderAPI().emitOrder(orderid);
    return response.data;
  }
);

export const { clear } = orderSlice.actions;
export default orderSlice.reducer;
