import { createTheme } from "@mui/material";

const themeOptions = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#816096",
    },
    secondary: {
      main: "#B59FC3",
    },
  },
});

export default themeOptions;