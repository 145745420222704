import {
  Modal,
  Typography,
  Portal,
  Divider,
  Backdrop,
  CircularProgress,
  IconButton,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Order from "../../models/order";
import OrderItem from "../../models/orderItem";
import Product from "../../models/product";
import {
  initOrder,
  loadOrder,
  updateOrder,
} from "../../redux/shoppingBag/shoppingBagReducer";
import { clean, getOrders } from "../../redux/order/ordersReducer";
import OrderForm from "./form/orderForm";
import OrderList from "./orderList";
import { useSnackbar } from "notistack";

type Props = {
  open: boolean;
  onClose: () => void;
  product?: Product;
  quantity?: number;
};

const OrdersModal: React.FC<Props> = ({ open, product, quantity, onClose }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const dispatch = useAppDispatch();
  const { orders } = useAppSelector((state) => state.orders);

  useEffect(() => {
    if (open) {
      dispatch(getOrders({ status: "D" }));
    }
    return () => {
      dispatch(clean());
    };
  }, [dispatch, open]);

  const createOrderItemByProduct = (
    newID: number,
    product: Product
  ): OrderItem => {
    return {
      inv_product: product,
      localID: v4(),
      price: product.publicprice,
      productid: product.productid,
      quantity: quantity ?? 1,
      subtotal: quantity ?? 1 * product.publicprice,
      detid: newID,
    };
  };

  const handleOrderClick = async (orderid: number) => {
    setOpenBackdrop(true);
    const response = await dispatch(loadOrder(orderid));
    if (response && product && quantity) {
      const data = response.payload as any;
      const order = { ...(data.order.data as Order) };
      const details = [...(data.details.data as OrderItem[])];

      enqueueSnackbar(
        `Se ha seleccionado a: ${order.name} como pedido actual.`,
        {
          variant: "info",
        }
      );
      order.saleorder_detail = [
        ...details,
        createOrderItemByProduct(details.length + 1, product),
      ];
      await dispatch(updateOrder(order));
      enqueueSnackbar(`Se ha agregado el producto.`, {
        variant: "success",
      });
    }
    onClose();
    setOpenBackdrop(false);
  };

  const handleSubmitOrder = async (values: any) => {
    setOpenBackdrop(true);
    enqueueSnackbar(`Se esta registrando el nuevo pedido`);
    await dispatch(initOrder(values)); 
    enqueueSnackbar(`Se ha registrado el nuevo pedido.`, {
      variant: "success",
    });
    onClose();
    setOpenBackdrop(false);
  };

  return (
    <Portal>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        keepMounted
        open={open}
        onClose={onClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card
          sx={{
            position: "absolute",
            juistifyContent: "center",
            alighItems: "center",
            maxWidth: 1000,
            minWidth: 320,
            p: 4,
            overflow: "auto",
            maxHeight: "100vh",
          }}
        >
          <CardContent>
            <IconButton onClick={onClose}>
              <CloseTwoToneIcon />
            </IconButton>
            <Typography id="modal-title" variant="h6" component="h2">
              Pedido nuevo
            </Typography>
            <Typography id="modal-description" sx={{ mt: 1 }}>
              Ingresa los datos para registrar un nuevo pedido, o selecciona un
              pedido pendiente de emitir
            </Typography>
            <OrderForm
              orderItem={product && createOrderItemByProduct(1, product)}
              onSubmit={(values) => {
                handleSubmitOrder(values);
              }}
            />
            {orders.length > 0 && (
              <>
                <Divider sx={{ marginY: 1 }} />
                <Typography id="modal-title" variant="h6" component="h2">
                  Pedidos pendientes
                </Typography>
                <OrderList
                  orders={orders}
                  handleOrderClick={(orderid) => handleOrderClick(orderid)}
                />
              </>
            )}
          </CardContent>
          <CardActions>
            <Button onClick={onClose}>Cancelar</Button>
          </CardActions>
        </Card>
      </Modal>
    </Portal>
  );
};

export default OrdersModal;
