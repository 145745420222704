import { Divider, Typography } from "@mui/material";
import { Container } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks";

import {
  clear as clearProduct,
  requestProduct,
} from "../redux/product/productReducer";

import { AppBar } from "../components/layout";
import ProductDetail from "../components/product/productDetail";
import ProductNotFound from "../components/product/productNotFound";
import ProductSkeleton from "../components/product/productSkeleton";
import ProductListGrid from "../components/product/productListGrid";
import {
  changeCategory,
  setDisableSearchText,
} from "../redux/product/productListReducer";

const Product: React.FC = () => {
  const location = useLocation();
  const { productid } = useParams();

  const dispatch = useAppDispatch();
  const { product, status, error } = useAppSelector((state) => state.product);
  const [showRelatedProducts, setShowRelatedProducts] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const getData = async () => {
      if (productid) {
        const product = await dispatch(requestProduct(Number(productid)));
        if (product) {
          dispatch(
            changeCategory((product.payload as any).productData.data.categoryid)
          );
          dispatch(setDisableSearchText(true));
          setShowRelatedProducts(true);
        }
      }
    };

    getData();

    return () => {
      dispatch(setDisableSearchText(false));
      dispatch(changeCategory(undefined));
      dispatch(clearProduct());
    };
  }, [dispatch, productid]);

  return (
    <>
      <AppBar />
      <Container sx={{ paddingBottom: 8, paddingTop: 10 }}>
        {status === "succeeded" ? (
          <>
            <ProductDetail />
            <Divider sx={{ my: 2 }} />

            {product && showRelatedProducts && (
              <ProductListGrid
                header={
                  <Typography variant="h5" mb={1}>
                    Productos relacionados
                  </Typography>
                }
                hideFilters
              />
            )}
          </>
        ) : status === "failed" ? (
          <ProductNotFound error={error ?? ""} />
        ) : (
          <ProductSkeleton />
        )}
      </Container>
    </>
  );
};

export default Product;
