import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  findSelectedProduct,
  selectColorID,
  selectSizeID,
} from "../../redux/product/productReducer";
import capitalizeFirstLetter from "../../utils/capitalize";
import AddToOrder from "../order/addToOrder";
import ProductAvailability from "./productAvailability";
import ProductImageCarousel from "./productImagesCarousel";
import ProductColors from "./productColors";
import ProductSizes from "./productSizes";
import currency from "../../utils/currency";

const ProductDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    product,
    images,
    colors,
    sizes,
    selectedColor,
    selectedSize,
    selectedProduct,
  } = useAppSelector((state) => state.product);
  const theme = useTheme();

  const handleChangeColor = (id: number) => {
    dispatch(selectColorID(id));
  };
  const handleChangeSize = (id: number) => {
    dispatch(selectSizeID(id));
  };

  useEffect(() => {
    dispatch(findSelectedProduct());
  }, [dispatch, selectedColor, selectedSize]);

  if (product)
    return (
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} xl={5}>
          <ProductImageCarousel images={images ?? []} />
        </Grid>
        <Grid item xs={12} md={6} xl={7}>
          <Typography variant="h3" color={theme.palette.secondary.main}>
            {currency.format(product.publicprice)}
          </Typography>
          <Box>
            <Typography variant="caption">{product.sku}</Typography>
            <Typography variant="h4">
              {capitalizeFirstLetter(product.name)}
            </Typography>
            <Typography variant="body1">
              {capitalizeFirstLetter(product.description)}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 0.5, maxWidth: 400, minWidth: 200 }}>
            <ProductSizes
              onChange={handleChangeSize}
              selectedID={selectedSize?.sizeid}
              sizes={sizes}
            />
          </Box>
          <Box sx={{ marginTop: 0.5 }}>
            <ProductColors
              onClick={handleChangeColor}
              selectedID={selectedColor?.colorid}
              colors={colors}
            />
          </Box>
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {selectedProduct ? (
              <ProductAvailability balance={selectedProduct?.balance ?? 0} />
            ) : (
              <Box sx={{ height: 20 }} />
            )}
            <AddToOrder
              product={selectedProduct}
              balance={selectedProduct?.balance ?? 0}
            />
          </Box>
        </Grid>
      </Grid>
    );
  return null;
};

export default ProductDetail;
