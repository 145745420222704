import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fetchState from "../fetchStates";
import Product from "../../models/product";
import ProductAPI from "../../repository/product";
import Color from "../../models/color";
import Image from "../../models/image";
import Size from "../../models/size";

interface ProductState {
  selectedProduct?: Product;
  selectedSize?: Size;
  selectedColor?: Color;
  product?: Product;
  colors: Color[];
  images: Image[];
  sizes: Size[];
  status: fetchState;
  error?: string;
}

const initialState: ProductState = {
  selectedProduct: undefined,
  selectedColor: undefined,
  selectedSize: undefined,
  product: undefined,
  colors: [],
  images: [],
  sizes: [],
  error: undefined,
  status: "idle",
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    clear: (state) => {
      state.selectedProduct = undefined;
      state.selectedColor = undefined;
      state.selectedSize = undefined;
      state.product = undefined;
      state.colors = [];
      state.images = [];
      state.sizes = [];
      state.error = undefined;
      state.status = "idle";
    },
    selectColorID: (state, action) => {
      var findedColor = state.colors.find((e) => e.colorid === action.payload);
      state.selectedColor = findedColor;
    },
    selectSizeID: (state, action) => {
      var findedSize = state.sizes.find((e) => e.sizeid === action.payload);
      state.selectedSize = findedSize;
    },

    findSelectedProduct: (state) => {
      var options = state.product?.productChildren ?? [];

      if (options.length === 0) {
        state.selectedProduct = state.product;
      }

      if (state.colors.length > 0)
        if (state.selectedColor) {
          options = options.filter(
            (e) => e.inv_color?.colorid === state.selectedColor?.colorid
          );
        } else return;

      if (state.sizes.length > 0)
        if (state.selectedSize) {
          options = options.filter(
            (e) => e.inv_size?.sizeid === state.selectedSize?.sizeid
          );
        } else return;

      if (!(options.length > 0)) {
        return;
      }
      state.selectedProduct = options[0];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(requestProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(requestProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.product = action.payload.productData.data;
        var productChildren = action.payload.children.data.items as Product[];

        state.images = action.payload.images.data.items;
        state.product!.productChildren = productChildren;

        state.sizes = productChildren
          .filter((e) => Boolean(e.inv_size))
          .map((e) => e.inv_size!)
          .filter(
            (val, index, arr) =>
              arr.map((e) => e.sizeid).indexOf(val.sizeid) === index
          );

        state.colors = productChildren
          .filter((e) => Boolean(e.inv_color))
          .map((e) => e.inv_color!)
          .filter(
            (val, index, arr) =>
              arr.map((e) => e.colorid).indexOf(val.colorid) === index
          );
      })
      .addCase(requestProduct.rejected, (state, action) => {
        state.status = "failed";

        if (action.error.message?.includes("404")) {
          state.error = "Producto no encontrado";
        } else {
          state.error = action.error.message ?? "Algo ha ocurrido";
        }
      });
  },
});

export const requestProduct = createAsyncThunk(
  "product/requestProduct",
  async (productid: number) => {
    const productAPI = new ProductAPI();
    const productResponse = await productAPI.getProductByID(productid);
    const imagesResponse = await productAPI.getProductImages(productid);
    const childrenProductsResponse = await productAPI.getProductChildren(
      productid
    );
    return {
      productData: productResponse.data,
      children: childrenProductsResponse.data,
      images: imagesResponse.data,
    };
  }
);

export const { clear, selectColorID, selectSizeID, findSelectedProduct } =
  productSlice.actions;
export default productSlice.reducer;
