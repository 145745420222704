import axiosInstance from "../axiosInterceptors";

class AuthenticationAPI {
  public tryLogin = async (username: String, password: String) => {
    var response = await axiosInstance.post(`/api/login`, {
      username,
      password,
    });
    return response;
  };

  public logout = async () => {
    await axiosInstance.post("/api/logout");
  };
}

export default AuthenticationAPI;
