import {
  Modal,
  Card,
  Portal,
  Typography,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import Product from "../../models/product";

type Props = {
  product: Product;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const RemoveItemOrderModal: React.FC<Props> = ({
  product,
  open,
  onConfirm,
  onCancel,
}) => {
  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = () => {
    onConfirm();
  };

  return (
    <Portal>
      <Modal
        keepMounted
        open={open}
        onClose={handleCancel}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Card
          sx={{
            position: "absolute",
            juistifyContent: "center",
            alighItems: "center",
            maxWidth: 400,
            minWidth: 340,
            borderWidth: 0,
            padding: 2
          }}
        >
          <CardContent>
            <Typography id="modal-title" variant="h6" component="h2">
              Eliminar producto
            </Typography>
            <Typography id="modal-description" sx={{ mt: 1 }}>
              Esta seguro de eliminar el producto <b>{product.name}</b>?
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button onClick={handleCancel} variant="text">
              Cancelar
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="error">
              Sí, Eliminar producto
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </Portal>
  );
};

export default RemoveItemOrderModal;
